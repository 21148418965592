import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import { useLocation } from "react-router";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { deleteAllValuesBeritaDanIklan } from "redux/actions/news&ads";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import { theme } from "./theme";
import config from "./redux/config";
import routes from "./routes";
import mpisRoutes from "./routes/mpisRoutes";
import carisRoutes from "./routes/carisRoutes";
import axios from "axios";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./assets/scss/main.scss";
import { getErrors, getSubdomain, setVersion } from "utils";
import "boxicons/css/boxicons.min.css";
import "antd/dist/antd.min.css";
import { API_ENDPOINTS } from "constants/endpoint";
import { carisHosts } from "constants/subMenu";
import ScrollReset from "./layouts/ScrollReset/ScrollReset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const history = createBrowserHistory();
const store = config();

function WrapperRouter({ selectRoutes }) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const allowedUrl =
      pathname === "/admin/berita-publisher/tambah" ||
      pathname === "/admin/berita-publisher/tambah/preview" ||
      pathname === "/admin/berita-publisher/edit/preview";
    const urlEditBerita = pathname?.includes("/admin/berita-publisher/edit");
    if (!(allowedUrl || urlEditBerita)) {
      dispatch(deleteAllValuesBeritaDanIklan());
    }
  }, [location, dispatch]);
  return (
    <>
      <ScrollReset />

      {renderRoutes(selectRoutes, { someProp: "vvvnnnmmm" })}
    </>
  );
}

function App() {
  const [faviconMpis, setFaviconMpis] = useState("");

  const [selectRoutes, setSelectRoutes] = useState(routes);

  const fetchFavicon = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.GET_FAVICON);
      setFaviconMpis(response.data);
    } catch (error) {
      getErrors(error.response);
      new Error(error);
    }
  };
  const fetchVersion = async () => {
    try {
      const { data } = await axios.get(API_ENDPOINTS.GET_VERSION);
      const { fe } = data.data;
      const feVersionData = localStorage.getItem("feVersion");
      if (!feVersionData) {
        setVersion(data.data);
        localStorage.setItem("feVersion", fe);
      } else if (fe !== feVersionData) {
        if (localStorage.getItem("token")) {
          localStorage.removeItem("feVersion", { path: "/" });
          toast("Your application has been updated to the latest version.", {
            onClose: () => {
              if ("caches" in window) {
                caches
                  .keys()
                  .then(cacheNames => {
                    return Promise.all(
                      cacheNames.map(cacheName => {
                        return caches.delete(cacheName);
                      })
                    );
                  })
                  .then(() => {
                    console.log("Service worker cache has been cleared!");
                  });
              }
              if (window.indexedDB && indexedDB.databases) {
                indexedDB.databases().then(databases => {
                  databases.forEach(db => {
                    indexedDB.deleteDatabase(db.name);
                  });
                  console.log("All IndexedDB databases have been cleared!");
                });
              }
              window.location.reload(true);
            },
          });
        }
      }
    } catch (error) {
      getErrors(error.response);
      throw error;
    }
  };

  useEffect(() => {
    fetchVersion();
    const intervalId = setInterval(() => {
      fetchVersion();
    }, 5 * 60 * 1000); // 5 menit * 60 detik * 1000 milidetik
    return () => clearInterval(intervalId);
  }, []); // dependencies array kosong agar useEffect dijalankan sekali saja

  const fetchStageApp = () => {
    // Favicon setup
    let favicon = document.getElementById("favicon");
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.id = "favicon";
      favicon.rel = "icon";
      document.head.appendChild(favicon);
    }

    // Ambil hostname
    const hostname = window.location.hostname;

    // Pola regex untuk validasi
    const mpisRegex = /^(dev\.staging\.mpis\.id|staging\.mpis\.id|mpis\.id|localhost)$/;
    const carisRegex = /^(.*\.)?(staging\.caris\.id|caris\.id|localhost)$/;

    if (mpisRegex.test(hostname)) {
      // Jika termasuk kategori MPIS
      setSelectRoutes(mpisRoutes);
      if (faviconMpis) favicon.href = faviconMpis;
      document.title = "MPIS";
      localStorage.setItem("themeColor", "#9545eb");
    } else if (carisRegex.test(hostname)) {
      // Jika termasuk kategori CARIS
      setSelectRoutes(carisRoutes);
      localStorage.setItem("themeColor", "#111827");
    } else {
      // Default behavior jika hostname tidak cocok
      console.warn("Hostname tidak dikenali:", hostname);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      getSubdomain();
      await fetchFavicon();
      fetchStageApp();
    };

    fetchData();
  }, []);

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ToastContainer />
            <WrapperRouter selectRoutes={selectRoutes} />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
