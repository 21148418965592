import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import navConfigAdmin from "./navConfig/navConfigAdmin";
import navConfigPublisher from "./navConfig/navConfigPublisher";
import { hardBaseUrl } from "../../services/urlConstant";
import axios from "axios";
import Swal from "sweetalert2";
import navConfig from "./navConfig";
import navConfigAssoc from "../DashboardPublisher/navConfig/navConfigAssoc";
import navConfigOther from "../DashboardPublisher/navConfig";
import navConfigPencipta from "./navConfigPencipta";
import { setPublisherId } from "../../constants/landingPage/publisherId";
import { setKonfigurasiMenu } from "constants/landingPage/konfigurasiMenu";
import * as Sentry from "@sentry/react";
import { getErrors } from "utils";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "white",
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  content2: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    container:
      "swal2-container swal2-center swal2-backdrop-show swal2-container-me",
  },
  showClass: {
    backdrop: "swal2-backdrop-show",
  },
  buttonsStyling: true,
});

const configureMenus = (userLogin, dispatch) => {
  const getIcon = [...navConfigOther, ...navConfigAssoc, ...navConfigPublisher, ...navConfigPencipta, ...navConfig, ...navConfigAdmin]
    .flatMap((item) => item?.items || [])
    .filter((item, index, array) => array.findIndex(i => i.title === item.title) === index);

  const isParameter = (string) => string === "Master" ? "Parameter" : string;
  const filterMenuActive = userLogin?.user_menu?.filter(({ is_active }) => is_active);
  const newMenu = filterMenuActive?.map(({ menu_text, children, id, page_url, is_active }) => ({
    href: page_url,
    show: is_active,
    id,
    icon: getIcon.find(({ title }) => title === isParameter(menu_text))?.icon,
    selectedIcon: getIcon.find(({ title }) => title === isParameter(menu_text))?.selectedicon,
    title: menu_text,
    children: (children || [])?.filter(({ is_active }) => is_active)?.map(({ is_active, ...item }) => ({
      ...item,
      href: item?.page_url,
      show: is_active,
      title: item?.menu_text,
      id: item?.id
    }))
  }));
  dispatch(setKonfigurasiMenu(newMenu));
};

function DashboardAdmin(props) {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const { konfigurasiMenu } = useSelector(state => state?.konfigurasiMenu);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [loadingComp, setLoadingComp] = useState(false);
  const [userLetter, setUserLetter] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationUpdate, setNotificationUpdate] = useState(false);
  const [notificationTotal, setNotificationTotal] = useState(0);
  const [userLogin, setUserLogin] = useState(null);
  const [afterGetMe, setAfterGetMe] = useState(false);
  const [calculateMenus, setCalculateMenus] = useState(false);
  const [aterCalculateMenus, setAterCalculateMenus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleId = localStorage?.getItem("role_id");
        const token = localStorage.getItem("token");

        setLoadingComp(true);

        const urlMe = `${hardBaseUrl}/me${roleId ? `/${roleId}` : ""}`;
        const resultMe = await axios.get(urlMe, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const userData = resultMe?.data?.data;
        setUserLogin(userData);
        setAfterGetMe(true);

        if (!roleId) {
          localStorage.setItem("role_id", userData?.role_id);
        }

        const urlNotifications = `${hardBaseUrl}/notification?page=1&size=10`;
        const responseNotifications = await axios.get(urlNotifications, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setNotifications(responseNotifications.data.data);

        if (notificationUpdate) {
          const responseUpdatedNotifications = await axios.get(
            urlNotifications,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setNotifications(responseUpdatedNotifications.data.data);
          setNotificationUpdate(false);
        }

        const urlNotificationTotal = `${hardBaseUrl}/notification/unread`;
        const responseNotificationTotal = await axios.get(
          urlNotificationTotal,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setNotificationTotal(responseNotificationTotal.data.data);
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchData();
  }, []);

  const handleFetchError = error => {
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed!",
        text:
          error?.response?.data?.errors[0]?.message || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result?.isDismissed) {
          history.push("/");
        }
      });
  };

  useEffect(() => {
    if (afterGetMe) {
      try {
        handleUserLoginRole();
      } catch (error) {
        handleRoleError(error);
      }
    }
  }, [afterGetMe]);

  const handleUserLoginRole = () => {
    if (
      userLogin.role.type === "publisher" ||
      userLogin.role.type === "composer"
    ) {
      dispatch(setPublisherId(userLogin.publisher.publisher_id));
    }
    setUserLetter("M");
    setCalculateMenus(true);
    setAfterGetMe(false);
  };

  const handleRoleError = error => {
    Sentry.captureMessage("Error afterGetMe: " + error.message);
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed After Get Me!",
        text: error?.response || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result.isConfirmed) {
          setCalculateMenus(true);
          setAfterGetMe(false);
        }
      });
  };



  useEffect(() => {
    if (calculateMenus) {
      try {
        configureMenus(userLogin, dispatch);
      } catch (err) {
        handleMenuError(err);
      }

      setLoadingComp(false);
      setCalculateMenus(false);
      setAterCalculateMenus(true);
    }
  }, [calculateMenus]);

  useEffect(() => configureMenus(userLogin, dispatch), [userLogin?.user_menu]);

  const handleMenuError = err => {
    Sentry.captureMessage("Error calculateMenus: " + err.message);
    swalWithBootstrapButtons.fire({
      icon: "error",
      title: "Failed Calculate Menu!",
      text: err?.message || "Error",
      showCloseButton: false,
    });
  };

  const handleMarkAsRead = async (e, id) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/notification/${id}`;

    try {
      await axios.put(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotificationUpdate(true);
    } catch (error) {
      let errMessage = getErrors(error.response);
      Sentry.captureMessage("Error catch update notif: " + errMessage);
    }
  };

  // this condition probably wrong
  const navigationCondition =
    location.pathname !== "/admin/manajemen-web/preview" &&
    location.pathname === "/admin/berita-publisher/tambah/preview" ||
    location.pathname === "/admin/berita-publisher/edit/preview" ||
    location.pathname === "/admin/berita/tambah/preview" ||
    location.pathname === "/admin/berita/edit/preview" ||
    location.pathname === "/admin/finalisasi-berita/view/preview";
  return (
    <>
      {!navigationCondition && (
        <>
          <TopBar
            userProfileImage={userLogin?.display_picture_url}
            theRole={userLogin?.role?.type}
            nameOfRole={userLogin?.role?.name}
            theName={userLogin?.publisher?.name}
            imgOfPublisher={userLogin?.publisher?.profile_image}
            onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
            loadingComponent={loadingComp}
            userFirstLetter={userLetter}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
            notificationStatus={notificationTotal}
            userLogin={userLogin}
          />
          <NavBar
            theColor={userLogin?.publisher?.theme_color}
            onMobileClose={() => setOpenNavBarMobile(false)}
            openMobile={openNavBarMobile}
            loadingComponent={loadingComp}
            selectedNavItems={konfigurasiMenu || []}
            userLogin={userLogin}
          />
        </>
      )}

      {aterCalculateMenus && (
        <div className={classes.container}>
          {[
            "/admin/berita-publisher/tambah/preview",
            "/admin/berita-publisher/edit/preview",
            "/admin/berita/tambah/preview",
            "/admin/berita/edit/preview",
            "/admin/finalisasi-berita/view/preview",
            "/admin/manajemen-web/preview",
          ].includes(location.pathname) ? (
            <div className={classes.content2}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, { userLogin })}
              </Suspense>
            </div>
          ) : (
            <div className={classes.content}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, {
                  userLogin,
                  userRole: userLogin?.role?.type,
                  rolesFor: userLogin?.role?.roles_for,
                  publisherChoosen: userLogin?.publisher,
                })}
              </Suspense>
            </div>
          )}
        </div>
      )}
    </>
  );
}

DashboardAdmin.propTypes = {
  route: PropTypes.object,
};

export { configureMenus };

export default DashboardAdmin;
