export const getErrors = errorResponse => {
  const errorData = errorResponse?.data?.errors;
  if (!errorData) return "An unknown error occurred";

  if (Array.isArray(errorData)) {
    return errorData[0]?.message || "An unknown error occurred";
  } else if (typeof errorData === "object") {
    return errorData.message || "An unknown error occurred";
  }
  return "An unknown error occurred";
};
export const validateEmail = email => {
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regexEmail.test(email);
};

export default { getErrors, validateEmail };
