import {
  Breadcrumbs,
  Container,
  Divider,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Card,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import Swal from "sweetalert2";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  textFeature: {
    margin: "10px 0px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    padding: "10px 28px 10px 28px",
    backgroundColor: "black",
    color: "white",
    width: "213px",
    height: "40px",
    borderRadius: "6px",
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  autoInvoice: {
    margin: "10px 0px"
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "28px",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  buttonSave: {
    textTransform: "none",
    backgroundColor: "black",
    color: "white",
    width: "129px",
    height: "40px",
  },
  textSave: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
  selectPadding: {
    "& .MuiInputBase-fullWidth": {
      height: "44px",
    },
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  labelRequired: {
    color: "red"
  },
  checked: {},
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  cardContent: {
    padding: "1rem",
  },
  track: {
    backgroundColor: "#E4E7EB",
    borderRadius: "25px",
    border: "0px solid #606060",
    height: "auto",
    opacity: 1,
  },
}));
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}


const InvoiceConf = () => {
  const classes = useStyles();
  const [invoiceSwitch, setInvoiceSwitch] = useState(false);
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);

  const [period, setPeriod] = useState(0);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, "month")
      .toDate()
  );
  const [endDate, setEndDate] = useState(
    moment()
      .subtract(1, "month")
      .toDate()
  );
  const handleChangeStartDate = date => {
    setStartDate(date);
  };
  const handleChangeEndDate = date => {
    if (date < startDate) {
      setEndDate(startDate);
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleRangeMonthChange = event => {
    setPeriod(event.target.value);
    if (event.target.value == 0) {
      setStartDate(new Date().setMonth(new Date().getMonth() - 1));
      setEndDate(new Date().setMonth(new Date().getMonth() - 1));
    } else if (event.target.value > 0) {
      setStartDate(
        new Date().setMonth(new Date().getMonth() - event.target.value)
      );
      setEndDate(new Date().setMonth(new Date().getMonth() - 1));
    }
  };

  const handleChangeInvoiceSwitch = event => {
    setInvoiceSwitch(event.target.checked);
  };
  const formatDate = date => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };
  const saveInvoice = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/configuration/invoice`;
    const payload = {
      period: Number(period),
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      is_active_flag: invoiceSwitch,
    };
    axios
      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Invoice Configuration has been saved",
        }).then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invoice Configuration failed to save",
        });
      });
  };
  const getInvoiceData = () => {
    try {
      setLoadingPageSkeleton(true);
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher/configuration/invoice`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setInvoiceSwitch(res.data.data.is_active_flag);
          setPeriod(res.data.data.period);
          setStartDate(new Date(res.data.data.start_date));
          setEndDate(new Date(res.data.data.end_date));
          setLoadingPageSkeleton(false);
        });
    } catch (err) {
      setLoadingPageSkeleton(false);

      new Error(err);
    }
  };
  useEffect(() => {
    getInvoiceData();
  }, []);
  return (
    <Page className={classes.root} title="Invoice">

      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.title}>
                Configuration
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>
                Configuration
              </Typography>
              <Typography className={classes.breadCrumbsActive}>
                Invoice
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <ButtonGroupTop />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography variant="h3" className={classes.subtitle}>
                Invoice
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Typography className={classes.textFeature}>
                This feature is to active/deactivate invoice claims which can
                be done automatically
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item xs={4} md={12} sm={4} lg={12} xl={12}>
            {loadingPageSkeleton ? (
              <SkeletonComponent variant="wave" />
            ) : (
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Card className={classes.cardContent}>
                    <ThemeProvider theme={theme}>
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Switch
                            checked={invoiceSwitch}
                            onChange={handleChangeInvoiceSwitch}
                            name="checkedB"
                            color="primary"
                            classes={{
                              root: classes.rootSwitch,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography className={classes.autoInvoice}>
                            Automatically Invoice
                          </Typography>
                        </Grid>
                      </Grid>
                    </ThemeProvider>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                        <Box display="flex" gridGap={1}>
                          <Typography>Period</Typography>
                          <Typography className={classes.labelRequired}>*</Typography>
                        </Box>
                        <TextField
                          defaultValue={0}
                          variant="outlined"
                          value={period}
                          onChange={handleRangeMonthChange}
                          className={classes.selectPadding}
                          name="numberformat"
                          id="formatted-numberformat-input"
                          margin="dense"
                          fullWidth={true}
                          required
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {"Month"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                            <Box display="flex" gridGap={1}>
                              <Typography>Start Date</Typography>
                              <Typography className={classes.labelRequired}>*</Typography>
                            </Box>
                            <KeyboardDatePicker
                              id="outlined-basic"
                              disabled
                              variant="outlined"
                              onChange={handleChangeStartDate}
                              value={startDate}
                              className={classes.selectPadding}
                              name="startDate"
                              format="DD-MM-YYYY"
                              inputVariant="outlined"
                              required
                              fullWidth={true}
                              margin="dense"
                            />
                          </Grid>
                          <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                            <Box display="flex" gridGap={1}>
                              <Typography>End Date</Typography>
                              <Typography className={classes.labelRequired}>*</Typography>
                            </Box>
                            <KeyboardDatePicker
                              id="outlined-basic"
                              disabled
                              variant="outlined"
                              onChange={handleChangeEndDate}
                              className={classes.selectPadding}
                              value={endDate}
                              name="endDate"
                              format="DD-MM-YYYY"
                              inputVariant="outlined"
                              required
                              fullWidth={true}
                              margin="dense"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item md={12} justifyContent="flex-end">
                  <Box justifyContent="flex-end" display="flex">
                    <PrimaryButton label="Save" onClick={saveInvoice} />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

    </Page>
  );
};
export default InvoiceConf;
