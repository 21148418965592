import { Container, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Box, Grid } from "@mui/material";
import { ButtonGroupTop } from "components/atoms/Button";
import axios from "axios";
import {
  CurrencyDisplay,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SearchTextInput,
  SkeletonComponent,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import { formatDate, getErrors, textCapitalization } from "utils";
import { hardBaseUrl } from "../../../services/urlConstant";

function CostAllocation() {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: Number(paramsPage) || 1,
    per_page: Number(paramsSize) || 10,
    search: paramsSearch || "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher/cost-allocation`, {
        headers,
        params: queryParams,
      });

      const { data, total_page } = res?.data?.data;
      setDataTable(data);
      setTableTotalPage(total_page);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async value => {
    setLoadingPage(true);
    axios
      .delete(
        `${hardBaseUrl}/publisher/cost-allocation/${value.cost_allocation_id}`,
        { headers }
      )
      .then(() =>
        ModalSuccess("Cost Allocation has been deleted").then(result => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
      )
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingPage(false));
  };

  useEffect(() => {
    debounceDataTable();
    return () => {
      debounceDataTable.cancel();
    };
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Cost Allocation">
      <Container maxWidth={false}>
        <HeaderTitle title="Cost Allocation" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchTextInput
              placeholder="Search"
              value={queryParams?.search}
              onChange={e =>
                handleChangeQueryParams(e?.target?.value, "search")
              }
            />
          </Grid>
          <Grid item>
            <PrimaryButton
              label="Create Tresshold"
              startIcon={<AddIcon />}
              onClick={() => history.push("/admin/create-tresshold")}
              size="large"
            />
          </Grid>
        </Grid>
        <Box my="16px">
          <PrimaryTextButton
            label="Set Default Tresshold"
            onClick={() => history.push("/admin/default-tresshold")}
            endIcon={<ArrowForwardIcon />}
          />
        </Box>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <InnoTable
            isLoading={false}
            columns={columnTable}
            items={dataTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "size")
            }
            isHaveAction
            handleView={item =>
              history.push({
                pathname: `/admin/detail-cost-allocation/${item?.cost_allocation_id}`,
                state: { customer: item },
              })
            }
            handleDelete={handleDelete}
            isUsingDeleteConfirmation={true}
            deleteName={"composer_name"}
          />
        )}
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/cost-allocation",
  },
  {
    label: "Cost Allocation",
    active: true,
  },
];
const columnTable = [
  {
    name: "composer_name",
    title: "Composer/Author Name",
  },
  {
    name: "date",
    title: "Date",
    renderText: date => (date ? formatDate(moment(date, "D MMMM YYYY")) : "-"),
  },
  {
    name: "description",
    title: "Description",
  },
  {
    name: "tresshold",
    title: "Tresshold",
  },
  {
    name: "value",
    title: "Value Cost Allocation",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item?.value} decimalScale={2} prefix="Rp" />
    ),
  },
  {
    name: "value_allocated",
    title: "Allocated",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.value_allocated}
        decimalScale={2}
        prefix="Rp"
      />
    ),
  },
  {
    name: "status",
    title: "Status",
    renderText: item => textCapitalization(item),
  },
];
export default CostAllocation;
