import { Container, Divider } from "@material-ui/core";
import { Box } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  CustomTable,
  ModalError,
  Page,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { formatDate, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const PaymentMutation = () => {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/balance-mutation`,
        { headers }
      );
      setDataTable(res?.data?.data || []);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDataTable();
  }, []);

  return (
    <Page className={classes.root} title="Balance Mutation">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Balance Mutation"
            subTitle="Feature for showing balance mutation in payment transaction"
            mb="16px"
          />

          <CustomTable
            columnTable={columnTable}
            data={dataTable}
            maxHeight="100%"
          />
        </Container>
      )}
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/pencipta/dashboard-pencipta",
  },
  {
    label: "Transaction",
    link: "/pencipta/payment",
  },
  {
    label: "Payment",
    active: true,
  },
];
const columnTable = [
  {
    name: "due_date",
    title: "Due Date",
    renderText: item => item && formatDate(item),
  },
  {
    name: "description",
    title: "Description",
    renderText: item => item || "-",
  },
  {
    name: "all",
    title: "Value",
    headerAlign: "right",
    renderText: item => (
      <Box color={item?.type === "in" ? "green" : "red"}>
        <CurrencyDisplay value={item?.value} prefix="Rp" />
      </Box>
    ),
  },
  {
    name: "balance",
    title: "Balance",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp" />,
  },
];

export default PaymentMutation;
