import { Container, Divider } from "@material-ui/core";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalPreviewImage,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";
import ModalFormBank from "./components/ModalFormBank";
import { Add } from "@material-ui/icons";

function Bank() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [preload, setPreload] = useState(null);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handlePreviewLogo = item => {
    setModalVisible(true);
    setPreviewImage(item);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/bank`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/bank/${id}`, {
        headers,
      });
      ModalSuccess("Succesfully delete bank").then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const handleSubmit = async ({ payload, bankLogo }) => {
    const method = preload ? axios.put : axios.post;
    const modifiedPayload = {
      ...payload,
      ...(bankLogo?.image && { image_logo: bankLogo.image }),
    };
    const formData = new FormData();
    Object.keys(modifiedPayload).forEach(key => {
      if (key !== "profile_image" && key !== "bank_id") {
        formData.append(key, modifiedPayload[key]);
      }
    });
    try {
      setLoadingButton(true);
      await method(
        `${hardBaseUrl}/bank${preload ? `/${preload?.bank_id}` : ""}`,
        formData,
        { headers }
      );
      setModalFormVisible(false);
      ModalSuccess(
        `Successfully ${preload ? "Update" : "Create"} Bank`
      ).then(() => getDataTable());
    } catch (error) {
      setModalFormVisible(false);
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingButton(false);
      setPreload(null);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Bank">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid container justifyContent="space-between" my="16px">
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Bank"
                  onClick={() => {
                    setModalFormVisible(true);
                    setPreload(null);
                  }}
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable({ classes, handlePreviewLogo })}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  deleteConfirmationContent={`Are you sure you want to delete bank ${item?.name} ?`}
                  handleDelete={() => handleDelete(item?.bank_id)}
                  handleEdit={() => {
                    setModalFormVisible(true);
                    setPreload({
                      bank_id: item?.bank_id,
                      name: item?.name,
                      code: item?.code,
                      profile_image: item?.profile_image,
                    });
                  }}
                />
              )}
            />
          </Fragment>
        )}
      </Container>
      {modalVisible && (
        <ModalPreviewImage
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          imageUrl={previewImage}
        />
      )}
      {modalFormVisible && (
        <ModalFormBank
          open={modalFormVisible}
          onClose={() => setModalFormVisible(false)}
          preload={preload}
          handleSubmit={handleSubmit}
          loadingButton={loadingButton}
        />
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/bank-admin",
  },
  {
    label: "Bank",
    active: true,
  },
];
const columnTable = ({ classes, handlePreviewLogo }) => [
  {
    name: "name",
    title: "Bank Name",
  },
  {
    name: "code",
    title: "Bank Code",
  },
  {
    name: "profile_image",
    title: "Logo",
    renderText: item => (
      <img
        className={classes?.cursorPointer}
        onClick={() => {
          handlePreviewLogo(item);
        }}
        src={item}
        alt=""
        width={100}
      />
    ),
  },
];

export default Bank;
