import { Container } from "@material-ui/core";
import { Box, Divider, Grid } from "@mui/material";
import axios from "axios";
import {
  CurrencyDisplay,
  ModalError,
  MonthYearPicker,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { globalStyles } from "styles";
import { formatDate, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const PaymentMutation = () => {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [filterDate, setFilterDate] = useState(moment());
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    composer: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/publisher/transaction/payment/mutation`,
        {
          headers,
          params: {
            ...queryParams,
            month: filterDate.format("MM"),
            year: filterDate.format("YYYY"),
          },
        }
      );
      const { data, total_page } = res?.data?.data;
      setDataTable(data || []);
      setTableTotalPage(total_page || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleDownloadExcel = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/download/balance_composer`, {
        headers,
        responseType: "blob",
        params: {
          month: filterDate.format("MM"),
          year: filterDate.format("YYYY"),
        },
      });
      const url = window.URL.createObjectURL(new Blob([res?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `balance-mutation-${filterDate.format("MMMM")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDataTable();
  }, [queryParams, filterDate]);

  return (
    <Page className={classes.root} title="Balance Mutation">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes?.divider} />
          <Grid container justifyContent="space-between" my="16px">
            <Grid item>
              <SectionLabel
                title="Balance Mutation"
                subTitle="Feature for showing balance mutation in payment transaction"
              />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1}>
                <Grid item>
                  <MonthYearPicker
                    label="Filter Month"
                    value={filterDate}
                    onChange={date => setFilterDate(date)}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Download As Excel"
                    onClick={handleDownloadExcel}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <InnoTableV2
            isLoading={false}
            items={dataTable}
            columns={columnTable}
            page={queryParams?.page}
            rowsPerPage={queryParams?.per_page}
            totalPage={tableTotalPage}
            handleChangePage={(_, page) =>
              handleChangeQueryParams(page, "page")
            }
            handleChangeRowsPerPage={e =>
              handleChangeQueryParams(e?.target?.value, "per_page")
            }
          />
        </Container>
      )}
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/admin/payment",
  },
  {
    label: "Payment",
    link: "/admin/payment",
  },
  {
    label: "Balance Mutation",
    active: true,
  },
];
const columnTable = [
  {
    name: "date",
    title: "Due Date",
    renderText: item => formatDate(item),
  },
  {
    name: "description",
    title: "Description",
    renderText: item => item || "-",
  },
  {
    name: "all",
    title: "Value",
    headerAlign: "right",
    renderText: item => {
      const out = item?.mutation_type === "out";
      const value = out ? item?.value_out : item.value_in;
      const prefix = `${out ? "- " : "+ "}Rp`;
      return (
        <Box color={out ? "red" : "green"}>
          <CurrencyDisplay value={value} prefix={prefix} decimalScale={2} />
        </Box>
      );
    },
  },
  {
    name: "balance",
    title: "Balance",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item} prefix="Rp" decimalScale={2} />
    ),
  },
];
export default PaymentMutation;
