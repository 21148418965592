import { Cancel } from "@material-ui/icons";
import { Box, Dialog, Paper } from "@mui/material";
import { globalStyles } from "styles";

const ModalPreviewImage = ({ open, onClose, imageUrl }) => {
  const classes = globalStyles();
  return (
    <Dialog onClose={onClose} open={open}>
      <Paper elevation={0} />
      <Box position="absolute" right={0}>
        <Cancel onClick={onClose} className={classes?.cursorPointer} />
      </Box>
      <img src={imageUrl} alt="" />
      <Paper />
    </Dialog>
  );
};

export default ModalPreviewImage;
