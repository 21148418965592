import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AlertSnackbar,
  ArrayChip,
  ArrayTagChip,
  CurrencyDisplay,
  FormLabel,
  MultipleSelectInputWithTags,
  Page,
  PrimaryButton,
  PrimaryTextButton,
  SelectInput,
  SkeletonComponent,
  SongUsageTrendChart,
  TableCellMultipleRow,
  TableWithTitle,
  YearPicker,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce, isEqual } from "lodash";
import moment from "moment-timezone";
import randomColor from "randomcolor";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import {
  allOptionsSelected,
  arrayToCommaSeparatedString,
  createAllFilterOption,
  currencyFormatting,
  fetchAndFilterOptions,
  filterArrayByKeyValue,
  getErrors,
  handleChangeFilterAllAfterSearch,
  numberFormatting,
} from "utils";

function Dashboard({ userLogin }) {
  const classes = globalStyles();
  const history = useHistory();
  const location = useLocation();
  const isFirstLoad = useRef(true);
  const isFirstFilter = useRef(true);
  const userRole = localStorage?.getItem("role");
  const publisherName = userLogin?.publisher?.name || "";
  const roleSociety = userRole === "society";
  const rolePublisher = userRole === "publisher";
  const isCaris = localStorage?.getItem("typeWeb") === "caris";
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsRevenue = urlParams.get("revenue_type");
  const paramsDSP = urlParams.get("dsp_id");
  const paramsPublisher = urlParams.get("publisher_id");
  const paramsOriginalPublisher = urlParams.get("original_publisher_id");
  const paramsYear = urlParams.get("year");
  const paramsProductType = urlParams.get("right_type_id");
  const paramsMenu = urlParams.get("menu");

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedMenuChart, setSelectedMenuChart] = useState(
    Number(paramsMenu) || 1
  );
  const [baseOptionDsp, setBaseOptionDsp] = useState([]);
  const [optionDsp, setOptionDsp] = useState([]);
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [baseOptionPublisher, setBaseOptionPublisher] = useState([]);
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [
    baseOptionOriginalPublisher,
    setBaseOptionOriginalPublisher,
  ] = useState([]);
  const [optionRightType, setOptionRightType] = useState();
  const [selectedDSP, setSelectedDSP] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState([]);
  const [selectedOriginalPublisher, setSelectedOriginalPublisher] = useState(
    []
  );
  const [selectedRevenue, setSelectedRevenue] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: paramsDSP || "",
    original_publisher_id: paramsOriginalPublisher || "",
    year: Number(paramsYear) || moment().year(),
    publisher_id: paramsPublisher || "",
    ...(isCaris && {
      revenue_type: paramsRevenue || "all",
    }),
    ...(roleSociety && {
      right_type_id: paramsProductType || "",
    }),
  });
  const [tableParams, setTableParams] = useState({
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [optionDspParams, setOptionDspParams] = useState({
    page: 1,
    search: "",
  });
  const [optionPublisherParams, setOptionPublisherParams] = useState({
    page: 1,
    search: "",
  });
  const [
    optionOriginalPublisherParams,
    setOptionOriginalPublisherParams,
  ] = useState({
    publisher_id: "",
    page: 1,
    size: 10,
    search: "",
  });
  const [tableDashboard, setTableDashboard] = useState([]);
  const [tableTraffic, setTableTraffic] = useState([]);
  const [tableRevenue, setTableRevenue] = useState([]);
  const [tableDataComposer, setTableDataComposer] = useState([]);
  const [csvArray, setCsvArray] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const prevDspPagination = usePrevious(optionDspParams.search);
  const prevPublisherPagination = usePrevious(optionPublisherParams.search);
  const prevOriginalPublisherPagination = usePrevious(
    optionOriginalPublisherParams.search
  );
  const prevParamsPublisher = usePrevious(queryParams?.publisher_id);
  const prevOptionOriginalPublisher = usePrevious(optionOriginalPublisher);
  const societyPublisher = selectedMenuChart === 1;

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      [key]: value,
    }));
    handleChangePageParams(value, key);
  };
  const handleChangeTableParams = (value, key) => {
    setTableParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };
  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeFilterDSP = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedDSP(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedDSP,
        checked,
        option,
        key: "dsp_id",
        list: baseOptionDsp,
        handleChangeQueryParams,
        params: optionDspParams,
      })
    );
  };
  const handleSearchFilterDSP = value => {
    setOptionDspParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedPublisher,
        checked,
        option,
        key: "publisher_id",
        list: baseOptionPublisher,
        handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterPublisher = value => {
    setOptionPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterOriginalPublisher = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedOriginalPublisher(
      handleChangeFilterAllAfterSearch({
        selectedList: selectedOriginalPublisher,
        checked,
        option,
        key: "original_publisher_id",
        list: baseOptionOriginalPublisher,
        handleChangeQueryParams: handleChangeQueryParams,
        params: optionPublisherParams,
      })
    );
  };
  const handleSearchFilterOriginalPublisher = value => {
    setOptionOriginalPublisherParams(prev => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const handleChangeFilterRevenue = (event, option) => {
    const { checked } = event?.target || false;
    setSelectedRevenue(prev => {
      const isAllOption = option?.right_type_id === "all";
      const selectedFilter = checked
        ? [...prev, option]
        : filterArrayByKeyValue(prev, option, "right_type_id");

      if (isAllOption) {
        if (checked) {
          const filterParams = arrayToCommaSeparatedString(
            optionRightType.filter(item => item?.right_type_id !== "all"),
            "right_type_id"
          );
          handleChangeQueryParams(filterParams, "right_type_id");
          return [...optionRightType];
        } else {
          handleChangeQueryParams("", "right_type_id");
          return [];
        }
      } else {
        const filteredWithoutAll = selectedFilter.filter(
          item => item?.right_type_id !== "all"
        );
        const allOptionsSelected = optionRightType
          .filter(item => item?.right_type_id !== "all")
          .every(opt =>
            filteredWithoutAll.some(
              selected => selected.right_type_id === opt.right_type_id
            )
          );
        const finalSelection = allOptionsSelected
          ? [...filteredWithoutAll, { right_type_id: "all" }]
          : filteredWithoutAll;
        const filterParams = arrayToCommaSeparatedString(
          finalSelection.filter(item => item?.right_type_id !== "all"),
          "right_type_id"
        );
        handleChangeQueryParams(filterParams, "right_type_id");

        return finalSelection;
      }
    });
  };

  const getDefaultDSP = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/dashboard/options/valuated_dsp`,
        {
          headers,
          params: {
            page: 1,
            size: 3,
            year: queryParams?.year,
          },
        }
      );
      handleChangeQueryParams(
        arrayToCommaSeparatedString(res?.data?.data, "dsp_id"),
        "dsp_id"
      );
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getOptionDSP = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/dsp",
      params: optionDspParams,
      label: "name",
      key: "dsp_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionDspParams.search,
      prevPagination: prevDspPagination,
      setOptions: setOptionDsp,
      setBaseOptions: setBaseOptionDsp,
      selectedOptions: selectedDSP,
      setSelectedOptions: setSelectedDSP,
    });
  const debounceOptionDSP = useCallback(
    debounce(() => {
      getOptionDSP();
    }, 500),
    [optionDspParams]
  );
  const getOptionPublisher = () => {
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/publisher",
      params: optionPublisherParams,
      label: "name",
      key: "publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionPublisherParams.search,
      prevPagination: prevPublisherPagination,
      setOptions: setOptionPublisher,
      setBaseOptions: setBaseOptionPublisher,
      selectedOptions: selectedPublisher,
      setSelectedOptions: setSelectedPublisher,
    });
  };
  const debounceOptionPublisher = useCallback(
    debounce(() => {
      getOptionPublisher();
    }, 500),
    [optionPublisherParams]
  );
  const getOptionOriginalPublisher = () =>
    fetchAndFilterOptions({
      headers,
      endpoint: "/dashboard/options/original_publisher",
      params: optionOriginalPublisherParams,
      label: "name",
      key: "original_publisher_id",
      setLoading: setLoadingFilter,
      paramsCondition: optionOriginalPublisherParams.search,
      prevPagination: prevOriginalPublisherPagination,
      setOptions: setOptionOriginalPublisher,
      setBaseOptions: setBaseOptionOriginalPublisher,
      selectedOptions: selectedOriginalPublisher,
      setSelectedOptions: setSelectedOriginalPublisher,
    });
  const debounceOptionOriginalPublisher = useCallback(
    debounce(() => {
      getOptionOriginalPublisher();
    }, 500),
    [optionOriginalPublisherParams]
  );
  const getChartData = async () => {
    const url = `${hardBaseUrl}/publisher/dashboard/chart`;
    const options = {
      headers,
      params: queryParams,
    };
    try {
      const res = await axios.get(url, options);
      const { data } = res?.data || [];

      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const datasets = data.map(item => ({
          label: item?.dsp?.name,
          data: item?.chart.map(chartItem => chartItem?.base_currency_revenue),
          listener: item?.chart.map(chartItem => chartItem?.listener),
          color: item?.dsp?.color || "#000000",
        }));
        setChartData({
          labels,
          datasets,
        });
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getDashboardTableData = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/dashboard`, {
        headers,
        params: queryParams,
      });
      setTableDashboard(res?.data?.data || []);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getSongUsage = async () => {
    const url = `${hardBaseUrl}/publisher/song-usage`;
    const optionsTraffic = {
      headers,
      params: { ...queryParams, ...tableParams, sort: "stream-" },
    };
    const optionsRevenue = {
      headers,
      params: { ...queryParams, ...tableParams, sort: "revenue-" },
    };
    try {
      const resTraffic = await axios.get(url, optionsTraffic);
      const resRevenue = await axios.get(url, optionsRevenue);
      const resDataTraffic = resTraffic?.data?.data;
      const resDataRevenue = resRevenue?.data?.data;
      setTableTraffic(resDataTraffic);
      setTableRevenue(resDataRevenue);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getTableTrendComposer = async () => {
    const url = `${hardBaseUrl}/publisher/trend-of-using-composer-songs`;
    const options = {
      headers,
      params: { ...queryParams, ...tableParams, composer_id: "none" },
    };
    try {
      const res = await axios.get(url, options);
      const resData = res?.data?.data;
      setTableDataComposer(resData);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getCsvArray = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/dashboard/download`,
        {
          headers,
          params: queryParams,
        }
      );
      setCsvArray(res?.data?.data || []);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getSocietyReport = async () => {
    const url = `${hardBaseUrl}/dashboard/report/${
      societyPublisher ? "publisher" : "composer"
    }`;
    const { right_type_id, year, dsp_id } = queryParams;
    const options = {
      headers,
      params: {
        ...tableParams,
        right_type_id,
        year,
        dsp_id,
      },
    };
    try {
      const res = await axios.get(url, options);
      const { data, meta } = res?.data;
      if (data?.length > 0) {
        const labels = data[0].chart.map(item =>
          moment(item.date).format("MMM YY")
        );
        const modifiedData = data?.map(item => ({
          ...item,
          color: randomColor(),
        }));
        setTableDashboard(modifiedData || []);
        const pageCount = getTotalPage(meta?.total, tableParams?.size || 1);
        setTableTotalPage(pageCount);
        const datasets = modifiedData.map(item => ({
          label: societyPublisher ? item?.publisher_name : item?.composer_name,
          data: item?.chart.map(chartItem => chartItem?.base_currency_revenue),
          listener: item?.chart.map(chartItem => chartItem?.traffic),
          color: item?.color,
          showMark: false,
        }));
        setChartData({
          labels,
          datasets,
        });
      } else {
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getOptionRightType = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/right_types `, { headers });
      const { data } = res?.data;
      const updatedOptionsList = [
        createAllFilterOption({
          label: "right_type_name",
          key: "right_type_id",
        }),
        ...data?.map(item => ({
          ...item,
          right_type_id: item?.id,
        })),
      ];
      setOptionRightType(updatedOptionsList);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage(error?.response ? getErrors(error?.response) : error);
    }
  };
  const fetchFilter = async () => {
    setLoadingFilter(true);

    const fetchActions = [getOptionDSP()];

    if (!roleSociety) fetchActions.push(getOptionPublisher());
    if (!queryParams?.dsp_id) fetchActions.push(getDefaultDSP());
    if (roleSociety) fetchActions.push(getOptionRightType());

    try {
      await Promise.all(fetchActions);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage("Error fetching data for filter");
    } finally {
      setLoadingFilter(false);
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const fetchActions = roleSociety
      ? [getSocietyReport()]
      : [
          getChartData(),
          getDashboardTableData(),
          getSongUsage(),
          getCsvArray(),
        ];
    if (isCaris) fetchActions.push(getTableTrendComposer());

    try {
      await Promise.all(fetchActions);
    } catch (error) {
      setAlertVisible(true);
      setAlertMessage("Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };

  const initializeFilter = async () => {
    if (optionDsp && optionDsp.length > 0 && queryParams?.dsp_id) {
      const selectedId = queryParams?.dsp_id?.split(",")?.map(id => id.trim());
      const selected = optionDsp.filter(option =>
        selectedId.includes(option.dsp_id.toString())
      );
      setSelectedDSP(
        allOptionsSelected({
          list: optionDsp,
          key: "dsp_id",
          selectedFilter: selected,
        })
          ? optionDsp
          : selected
      );
    }
    if (optionPublisher && optionPublisher.length > 0) {
      if (!queryParams?.publisher_id) {
        const defaultPublisherId = arrayToCommaSeparatedString(
          optionPublisher.filter(item => item?.publisher_id !== "all"),
          "publisher_id"
        );
        handleChangeQueryParams(defaultPublisherId, "publisher_id");
        setSelectedPublisher(optionPublisher);
      } else {
        const selectedId = queryParams.publisher_id
          .split(",")
          .map(id => id.trim());
        const selected = optionPublisher.filter(option =>
          selectedId.includes(option.publisher_id.toString())
        );
        setSelectedPublisher(
          allOptionsSelected({
            list: optionPublisher,
            key: "publisher_id",
            selectedFilter: selected,
          })
            ? optionPublisher
            : selected
        );
      }
    }
  };

  useEffect(() => {
    fetchFilter();
  }, []);
  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchData();
    }
    if (queryParams?.publisher_id) {
      setOptionOriginalPublisherParams(prev => ({
        ...prev,
        publisher_id: queryParams?.publisher_id,
      }));
    }
  }, [queryParams, selectedMenuChart, tableParams, isFirstLoad.current]);
  useEffect(() => {
    const isSearching = Boolean(optionDspParams?.search);
    if (isSearching) {
      debounceOptionDSP();
      return () => {
        debounceOptionDSP.cancel();
      };
    } else {
      getOptionDSP();
    }
  }, [optionDspParams, debounceOptionDSP]);
  useEffect(() => {
    const isSearching = Boolean(optionPublisherParams?.search);
    if (isSearching) {
      debounceOptionPublisher();
      return () => {
        debounceOptionPublisher.cancel();
      };
    } else {
      getOptionPublisher();
    }
  }, [optionPublisherParams, debounceOptionPublisher]);
  useEffect(() => {
    if (isFirstFilter.current) initializeFilter();
  }, [optionDsp, optionPublisher]);
  useEffect(() => {
    if (selectedDSP.length > 0 && selectedPublisher.length > 0) {
      isFirstFilter.current = false;
      isFirstLoad.current = false;
    }
  }, [selectedDSP, selectedPublisher]);
  useEffect(() => {
    if (optionOriginalPublisherParams?.publisher_id && !roleSociety) {
      const isSearching = Boolean(optionOriginalPublisherParams?.search);
      if (isSearching) {
        debounceOptionOriginalPublisher();
        return () => {
          debounceOptionOriginalPublisher.cancel();
        };
      } else {
        getOptionOriginalPublisher();
      }
    }
  }, [
    optionOriginalPublisherParams?.publisher_id,
    optionOriginalPublisherParams,
    debounceOptionOriginalPublisher,
  ]);
  useEffect(() => {
    if (!selectedPublisher.length) {
      setSelectedOriginalPublisher([]);
      if (
        prevParamsPublisher &&
        !isEqual(prevParamsPublisher, queryParams?.publisher_id)
      )
        handleChangeQueryParams("", "original_publisher_id");
      return;
    }
    const isPaginationEqual = isEqual(
      prevOriginalPublisherPagination,
      optionOriginalPublisherParams?.search
    );
    if (!isPaginationEqual) return;

    if (!optionOriginalPublisherParams?.search && paramsOriginalPublisher) {
      const filteredOptions = optionOriginalPublisher.filter(
        item => item?.original_publisher_id !== "all"
      );
      const updatedSelected = selectedOriginalPublisher.filter(
        item =>
          item?.original_publisher_id !== "all" &&
          filteredOptions.some(
            option =>
              option.original_publisher_id === item.original_publisher_id
          )
      );

      const selectedId = paramsOriginalPublisher
        ?.split(",")
        ?.map(id => id.trim());
      const selected = filteredOptions.filter(option =>
        selectedId?.includes(option.original_publisher_id.toString())
      );
      const allSelected = allOptionsSelected({
        list: filteredOptions,
        key: "original_publisher_id",
        selectedFilter: paramsOriginalPublisher
          ? selected
          : selectedOriginalPublisher,
      });
      if (
        prevOptionOriginalPublisher &&
        !isEqual(prevOptionOriginalPublisher, optionOriginalPublisher)
      ) {
        handleChangeQueryParams(
          arrayToCommaSeparatedString(updatedSelected, "original_publisher_id"),
          "original_publisher_id"
        );
      }
      setSelectedOriginalPublisher(
        allSelected
          ? optionOriginalPublisher
          : paramsOriginalPublisher
          ? selected
          : updatedSelected
      );
    }
  }, [
    selectedPublisher,
    optionOriginalPublisherParams,
    optionOriginalPublisher,
  ]);
  const filterConfig = filterConfigList({
    isCaris,
    queryParams,
    handleChangeQueryParams,
    roleSociety,
    optionRightType,
    selectedRevenue,
    handleChangeFilterRevenue,
    selectedDSP,
    optionDsp,
    handleChangeFilterDSP,
    optionDspParams,
    handleSearchFilterDSP,
    selectedPublisher,
    optionPublisher,
    handleChangeFilterPublisher,
    optionPublisherParams,
    handleSearchFilterPublisher,
    rolePublisher,
    selectedOriginalPublisher,
    optionOriginalPublisher,
    handleChangeFilterOriginalPublisher,
    optionOriginalPublisherParams,
    handleSearchFilterOriginalPublisher,
  });

  return (
    <Page
      className={classes.root}
      title="Dashboard"
      data-testid="data-test-page-dashboard"
    >
      <AlertSnackbar
        open={alertVisible}
        message={alertMessage}
        onClose={() => setAlertVisible(false)}
      />
      <Container maxWidth={false}>
        <HeaderTitle
          title={`Dashboard ${publisherName && `| ${publisherName}`}`}
          breadcrumbData={breadcrumbData}
        />
        <Divider className={classes?.divider} />
        {!roleSociety && (
          <Typography variant="h4" gutterBottom>
            Trend of Song Usage
          </Typography>
        )}
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={1}
          spacing={2}
        >
          {roleSociety && (
            <Grid item>
              <ButtonGroup>
                {menuChart?.map(({ title, id }) => {
                  const selected = id === selectedMenuChart;
                  return (
                    <Button
                      className={
                        selected
                          ? classes.groupButtonSelected
                          : classes?.groupButton
                      }
                      key={id}
                      onClick={() => {
                        setSelectedMenuChart(id);
                        handleChangePageParams(id, "menu");
                      }}
                    >
                      {title}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <Card className={classes.filterArea}>
              <Grid container columnSpacing={1} direction="row">
                {filterConfig?.map((item, index) => (
                  <Grid item key={index} maxWidth={220}>
                    <Box>
                      <FormLabel label={item?.textValue || item?.label} />
                      {item?.type === "checkbox" ? (
                        <MultipleSelectInputWithTags
                          label={item?.label}
                          placeholder={item?.placeholder}
                          value={item?.value}
                          textValue={item?.textValue}
                          options={item?.options}
                          optionKey={item?.optionKey}
                          optionLabel={item?.optionLabel}
                          onChange={item?.onChange}
                          handleRemove={item?.handleRemove}
                          search={item?.search}
                          searchValue={item?.searchValue}
                          onChangeSearch={item?.onChangeSearch}
                          loading={loadingFilter}
                          disabled={item?.disabled || loadingFilter}
                          group={item?.group}
                        />
                      ) : item?.type === "yearPicker" ? (
                        <YearPicker
                          label={item?.label}
                          onChange={item?.onChange}
                          value={item?.value}
                        />
                      ) : (
                        <SelectInput
                          label={item?.label}
                          value={item?.value}
                          placeholder={item?.placeholder}
                          onChange={item?.onChange}
                          options={item?.options}
                          optionKey={item?.optionKey}
                          optionLabel={item?.optionLabel}
                          disabled={loadingFilter}
                          width={180}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Box>
            <Box
              border="1px solid #ebebeb"
              borderRadius="5px"
              padding="50px 20px"
              my={3}
            >
              {chartData?.datasets.length > 0 && selectedDSP?.length > 0 && (
                <ArrayTagChip
                  selectionsList={
                    selectedDSP.some(item => item.dsp_id === "all")
                      ? [{ dsp_id: "all", name: "All" }]
                      : selectedDSP
                  }
                  value="name"
                />
              )}
              <Box width="100%" mt={2}>
                <SongUsageTrendChart
                  chartData={chartData}
                  tooltipTrigger={roleSociety ? "axis" : "item"}
                  // tooltipTrigger="item"
                  // series={
                  //   !chartData?.datasets?.length
                  //     ? []
                  //     : chartData?.datasets?.map(series => ({
                  //         ...series,
                  //         valueFormatter: (value, { dataIndex }) => {
                  //           return (
                  //             <Box py={2}>
                  //               <Typography>
                  //                 Revenue :{" "}
                  //                 {currencyFormatting({
                  //                   value,
                  //                 })}
                  //               </Typography>
                  //               <Typography>
                  //                 Traffic :{" "}
                  //                 {numberFormatting({
                  //                   value: series?.listener?.[dataIndex],
                  //                 })}
                  //               </Typography>
                  //             </Box>
                  //           );
                  //         },
                  //       }))
                  // }
                />
              </Box>
            </Box>
            {!roleSociety && (
              <Grid container spacing={1} justifyContent="flex-end" my={2}>
                <Grid item>
                  <PrimaryButton
                    label="Download PDF"
                    onClick={() =>
                      history.push({
                        pathname: "/admin/dashboard/print-report",
                        state: {
                          chartData: chartData,
                          selectedDSP: selectedDSP,
                          tableData: tableDashboard,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item>
                  <CSVLink data={csvArray} filename="dashboard">
                    <PrimaryButton label="Download CSV" />
                  </CSVLink>
                </Grid>
              </Grid>
            )}
            <InnoTableV2
              isLoading={false}
              columns={columnTableDashboard({ societyPublisher, roleSociety })}
              items={tableDashboard}
              page={roleSociety && tableParams?.page}
              rowsPerPage={roleSociety && tableParams?.size}
              totalPage={roleSociety && tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeTableParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeTableParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <PrimaryButton
                  label="See Details"
                  onClick={() => {
                    const menuComposer = Number(selectedMenuChart) === 2;
                    const dspId = roleSociety ? paramsDSP : item?.dsp?.dsp_id;
                    const publisher = roleSociety
                      ? item?.publisher_id
                      : paramsPublisher;
                    const composerId = item?.composer_id;
                    const composerName = item?.composer_name;
                    history.push(
                      `/admin/review-lagu?dsp_id=${dspId}${
                        menuComposer
                          ? `&composer_id=${composerId}&composer_name=${composerName}`
                          : `&publisher_id=${publisher}`
                      }${
                        roleSociety
                          ? ""
                          : `&original_publisher_id=${paramsOriginalPublisher}`
                      }&year=${queryParams?.year}${
                        roleSociety ? `&menu=${selectedMenuChart}` : ""
                      }`
                    );
                  }}
                  width={110}
                />
              )}
            />
            {!roleSociety && (
              <Fragment>
                <TableWithTitle
                  label="Top 10 Chart Song Based On Traffic"
                  columns={columnTableTopTen(classes)}
                  items={tableTraffic}
                  isLoading={false}
                />
                <TableWithTitle
                  label="Top 10 Chart Song Based On Revenue"
                  columns={columnTableTopTen(classes)}
                  items={tableRevenue}
                  isLoading={false}
                />
              </Fragment>
            )}
            {isCaris && (
              <>
                <TableWithTitle
                  label="Trend of Using Composer/Author Songs"
                  columns={columnTableTrendComposer}
                  isHaveAction={true}
                  renderAction={item => (
                    <PrimaryButton
                      label="See Details"
                      onClick={() =>
                        history.push(
                          `/admin/review-lagu?dsp_id=${paramsDSP}&publisher_id=${paramsPublisher}&original_publisher_id=${paramsOriginalPublisher}&year=${paramsYear}&composer_id=${item?.composer_id}`
                        )
                      }
                      width={107}
                    />
                  )}
                  items={tableDataComposer}
                  isLoading={false}
                />
                <Grid container justifyContent="flex-end" my="16px">
                  <PrimaryTextButton
                    label="See More"
                    onClick={() =>
                      history.push(
                        `/admin/composer-trend?dsp_id=${paramsDSP}&publisher_id=${paramsPublisher}&original_publisher_id=${paramsOriginalPublisher}&year=${paramsYear}`
                      )
                    }
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </>
            )}
          </Box>
        )}
      </Container>
    </Page>
  );
}

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Dashboard",
    active: true,
  },
];
const optionRevenue = [
  {
    value: "all",
    name: "All Revenue",
  },
  {
    value: "publisher",
    name: "Publisher Revenue",
  },
  {
    value: "composer",
    name: "Composer Revenue",
  },
];
const menuChart = [
  {
    id: 1,
    title: "Publisher",
  },
  {
    id: 2,
    title: "Author/Composer",
  },
];
const columnTableTopTen = classes => [
  {
    name: "title",
    title: "Song Title",
  },
  {
    name: "all",
    title: "Composer/Author",
    renderText: item => {
      const listValue = item.composers?.map(
        ({ name, is_on_this_publisher }) => ({
          name,
          className: is_on_this_publisher ? classes?.highlightText : "",
        })
      );
      return <ArrayChip list={listValue} />;
    },
  },
  {
    name: "listener",
    title: <Box textAlign="right">Traffic</Box>,
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "base_currency_revenue",
    title: <Box textAlign="right">Revenue</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
];
const columnTableTrendComposer = [
  {
    name: "composer_name",
    title: "Composer/Author",
  },
  {
    name: "listener",
    title: <Box textAlign="right">Traffic</Box>,
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "royalty",
    title: <Box textAlign="right">Revenue</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
  {
    name: "advance",
    title: <Box textAlign="right">Advance Composer/Author</Box>,
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "balance",
    title: <Box textAlign="right">Balance</Box>,
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
    ),
  },
];
const columnTableDashboard = ({ societyPublisher, roleSociety }) => [
  {
    name: "all",
    title: !roleSociety ? "DSP" : societyPublisher ? "Publisher" : "Composer",
    renderText: item => (
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item>
          <Box
            bgcolor={roleSociety ? item?.color : item?.dsp?.color}
            height={20}
            width={20}
            border={
              roleSociety
                ? (!item?.color || item?.color === "#fffff") &&
                  "solid 1px #111827"
                : (!item?.dsp?.color || item?.dsp?.color === "#fffff") &&
                  "solid 1px #111827"
            }
          />
        </Grid>
        <Grid item>
          {!roleSociety
            ? item?.dsp?.name
            : societyPublisher
            ? item?.publisher_name
            : item?.composer_name}
        </Grid>
      </Grid>
    ),
  },
  ...(roleSociety
    ? []
    : [
        {
          name: "summaries",
          title: "Product Type",
          renderText: item => (
            <TableCellMultipleRow list={item} itemKey="product_type" />
          ),
        },
      ]),
  {
    name: roleSociety ? "traffic" : "summaries",
    title: <Box textAlign="right">Traffic</Box>,
    renderText: item =>
      roleSociety ? (
        <CurrencyDisplay value={item} />
      ) : (
        <TableCellMultipleRow list={item} itemKey="listener" type="number" />
      ),
  },
  ...(roleSociety
    ? []
    : [
        {
          name: "summaries",
          title: <Box textAlign="right">Original Currency</Box>,
          renderText: item => (
            <TableCellMultipleRow
              list={item}
              itemKey="original_currency_revenue"
              type="number"
              prefixKey="currency_symbol_code"
            />
          ),
        },
      ]),
  {
    name: roleSociety ? "base_currency_revenue" : "summaries",
    title: (
      <Box textAlign="right">{roleSociety ? "Revenue" : "Base Currency"}</Box>
    ),
    renderText: item =>
      roleSociety ? (
        <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
      ) : (
        <TableCellMultipleRow
          list={item}
          itemKey="base_currency_revenue"
          type="number"
          prefix="Rp "
        />
      ),
  },
  {
    name: roleSociety ? "advance_revenue" : "summaries",
    title: <Box textAlign="right">Advance DSP</Box>,
    renderText: item =>
      roleSociety ? (
        <CurrencyDisplay value={item} decimalScale={2} prefix="Rp " />
      ) : (
        <TableCellMultipleRow
          list={item}
          itemKey="advance"
          type="number"
          prefixKey="currency_symbol_code"
        />
      ),
  },
];
const revenueTypeFilterConfig = (queryParams, handleChangeQueryParams) => ({
  value: queryParams?.revenue_type,
  options: optionRevenue,
  textValue: "Revenue",
  optionKey: "value",
  optionLabel: "name",
  onChange: event =>
    handleChangeQueryParams(event?.target?.value, "revenue_type"),
});
const rightTypeFilterConfig = (
  optionRightType,
  selectedRevenue,
  handleChangeFilterRevenue
) => ({
  textValue: "Right Type",
  value: selectedRevenue,
  options: optionRightType,
  optionKey: "right_type_id",
  optionLabel: "right_type_name",
  onChange: handleChangeFilterRevenue,
  type: "checkbox",
});
const dspFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  watchParams,
}) => ({
  textValue: "DSP",
  value,
  options,
  optionKey: "dsp_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
  disabled: watchParams,
});
const publisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  rolePublisher,
  watchParams,
}) => ({
  textValue: "Publisher",
  value,
  options,
  optionKey: "publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
  group: rolePublisher,
  disabled: watchParams,
});
const originalPublisherFilterConfig = ({
  value,
  options,
  onChange,
  params,
  onChangeSearch,
  watchParams,
}) => ({
  textValue: "Original Publisher",
  value,
  options,
  optionKey: "original_publisher_id",
  optionLabel: "name",
  onChange,
  search: true,
  onChangeSearch,
  searchValue: params?.search,
  type: "checkbox",
  disabled: watchParams,
});
const yearFilterConfig = (queryParams, handleChangeQueryParams) => ({
  label: "Year",
  value: queryParams?.year,
  onChange: year => handleChangeQueryParams(year, "year"),
  type: "yearPicker",
});
const filterConfigList = ({
  isCaris,
  queryParams,
  handleChangeQueryParams,
  roleSociety,
  optionRightType,
  selectedRevenue,
  handleChangeFilterRevenue,
  selectedDSP,
  optionDsp,
  handleChangeFilterDSP,
  optionDspParams,
  handleSearchFilterDSP,
  selectedPublisher,
  optionPublisher,
  handleChangeFilterPublisher,
  optionPublisherParams,
  handleSearchFilterPublisher,
  rolePublisher,
  selectedOriginalPublisher,
  optionOriginalPublisher,
  handleChangeFilterOriginalPublisher,
  optionOriginalPublisherParams,
  handleSearchFilterOriginalPublisher,
}) => [
  ...(isCaris
    ? [revenueTypeFilterConfig(queryParams, handleChangeQueryParams)]
    : []),
  ...(roleSociety
    ? [
        rightTypeFilterConfig(
          optionRightType,
          selectedRevenue,
          handleChangeFilterRevenue
        ),
      ]
    : []),
  dspFilterConfig({
    value: selectedDSP,
    options: optionDsp,
    onChange: handleChangeFilterDSP,
    params: optionDspParams,
    onChangeSearch: handleSearchFilterDSP,
    watchParams: !selectedPublisher?.length,
  }),
  ...(!roleSociety
    ? [
        publisherFilterConfig({
          value: selectedPublisher,
          options: optionPublisher,
          onChange: handleChangeFilterPublisher,
          params: optionPublisherParams,
          onChangeSearch: handleSearchFilterPublisher,
          rolePublisher,
          watchParams: !selectedDSP?.length,
        }),
        originalPublisherFilterConfig({
          value: selectedOriginalPublisher,
          options: optionOriginalPublisher,
          onChange: handleChangeFilterOriginalPublisher,
          params: optionOriginalPublisherParams,
          onChangeSearch: handleSearchFilterOriginalPublisher,
          watchParams: !selectedPublisher.length || !selectedDSP.length,
        }),
      ]
    : []),
  yearFilterConfig(queryParams, handleChangeQueryParams),
];

export default Dashboard;
