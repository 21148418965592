import React, { useMemo, useRef, useState, useEffect } from "react";
import {
  Backdrop,
  Breadcrumbs,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Box,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { InnoImage } from "inno-ui";
import { PhotoshopPicker } from "react-color";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import UnggahLogo from "../../../../../assets/img/unggahLogo.svg";
import { Page, PrimaryButton } from "components";
import DialogUploadDokumen from "../../../../../pages/Admin/MasterAdmin/Dsp/TambahDsp/DialogUploadDokumen";
import { hardBaseUrl } from "../../../../../services/urlConstant";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#303030",
  borderStyle: "thin",
  backgroundColor: "lightgray",
  color: "black",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
const activeStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 101,
    color: "#fff",
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {
    marginTop: "6px",
  },
  button: {
    marginBottom: "20px",
    marginTop: "20px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  buttonSetting: {
    backgroundColor: "black",
    color: "white",
    width: "130px",
    height: "30px",
    textTransform: "none",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
    },
  },
  imgPreview: {
    maxWidth: "150px",
    maxHeight: "150px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  labelRequired: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    marginLeft: 2,
    fontSize: "14px",
    lineHeight: "20px",
    color: "red",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  rootSwitch: {
    width: "40px",
    height: "20px",
    padding: "0px",
    borderRadius: "10px",
    marginRight: "8px",
    position: "relative",
    backgroundColor: "green",
    opacity: 1,
  },
  "&.MuiSwitch-colorSecondary.Mui-checked": {
    backgroundColor: "black",
  },
  switchBase: {
    padding: "10px",
    color: "white",
    "&.Mui-checked": {},
    "&.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
    "&.MuiSwitch-colorSecondary.Mui-checked + $track": {
      border: "none",
      color: "white",
      backgroundColor: "black",
      opacity: 1,
    },
  },
  checked: {
    border: "none",
    color: "white",
    backgroundColor: "#E4E7EB",
    opacity: 1,
  },
  thumb: {
    color: "white",
    position: "absolute",
    width: "16px",
    height: "16px",
  },
  track: {
    backgroundColor: "#9c9c9c",
    opacity: 1,
  },
  colorSecondary: {
    "&.Mui-checked + .MuiSwitch-track": {},
    "&.Mui-checked": {
      color: "white",
    },
  },
  colorPrimary: {
    backgroundColor: "black",
  },
  selectFormControl: {
    "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      width: "320px",
      padding: "14px 14px",
    },
  },
  autoComplete: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      height: "44px",
    },
    "& .MuiOutlinedInput-root input.MuiInputBase-input.MuiOutlinedInput-input": {
      paddingLeft: "14px",
    },
  },
  mt5: {
    marginTop: "5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt16: {
    marginTop: "16px",
  },
  mt20: {
    marginTop: "20px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mb16: {
    marginBottom: "16px",
  },
  mb20: {
    marginBottom: "20px",
  },
  mr5: {
    marginRight: "5px",
  },
  mr10: {
    marginRight: "10px",
  },
  mr15: {
    marginRight: "15px",
  },
  mr16: {
    marginRight: "16px",
  },
  mr20: {
    marginRight: "20px",
  },
  ml5: {
    marginLeft: "5px",
  },
  ml10: {
    marginLeft: "10px",
  },
  ml15: {
    marginLeft: "15px",
  },
  ml16: {
    marginLeft: "16px",
  },
  ml20: {
    marginLeft: "20px",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  flex: {
    displa: "flex",
  },
  flexCenter: {
    displa: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
    border: "1px solid grey",
    padding: "8px",
    borderRadius: "8px",
  },
  flexBetween1: {
    display: "flex",
    justifyContent: "space-between",
  },
  displayNone: {
    display: "none",
  },
  divContainer: {
    border: "1px solid #9AA2B1",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
  },
  imgContainer: {
    width: "160px",
    height: "160px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  textCenter: {
    textAlign: "center",
    borderRadius: "6px",
    marginTop: "6px",
  },
  font14: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#111827",
  },
  pointer: {
    cursor: "pointer",
  },
  font18: {
    color: "black",
    fontSize: "18px",
  },
  block415: {
    display: "block",
    width: "415px",
  },
  gap4: {
    display: "flex",
    justifyContent: "right",
    paddingRight: "24px",
  },
}));

const TambahDsp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [color, setColor] = useState("#000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [file, setFile] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);
  const [dsp, setDsp] = useState("");
  const [nama, setNama] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [checkedVal, setcheckedVal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dokumen, setDokumen] = useState([]);
  const [files, setFiles] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [processMethod, setProcessMethod] = useState("integration");
  const [country, setCountry] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [currency, setCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const inputFile = useRef(null);
  const processMethodList = [
    {
      id: "integration",
      name: "Integration",
    },
    {
      id: "upload",
      name: "Upload",
    },
  ];
  const theme = createTheme({
    typography: {
      fontFamily: ["Helvetica Neue"].join(","),
    },
  });
  const handleDrop = items => {
    if (files !== null) {
      if (files?.length > 0) {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        let newFiles = files;
        const children = newFiles.concat(data);
        setFiles(children);
        setDokumen(children);
      } else {
        const data = [];
        items.forEach(item => {
          data.push(item);
        });
        setFiles(data);
        setDokumen(data);
      }
    } else {
      const data = [];
      items.forEach(item => {
        data.push(item);
      });
      setFiles(data);
      setDokumen(data);
    }
  };
  const onRemoveFile = idx => {
    setDokumen(dokumen.filter((_, i) => i !== idx));
    setFiles(files.filter((_, i) => i !== idx));
    acceptedFiles.splice(idx, 1);
  };
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: [".xlsx", "xls"],
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "10px",
      width: "48px",
      height: "24px",
    },
  }));
  const colorPrev = colorPreview();
  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "address":
        setAddress(value);
        break;
      case "dsp":
        setDsp(value);
        break;
      case "nama":
        setNama(value);
        break;
      case "color":
        setColor(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "telepon":
        setTelepon(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    getCountryList();
    getCurrencyList();
  }, []);
  const getCountryList = () => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/country/table?page=1&per_page=99&search=&sort=`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        setCountryList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };
  const getCurrencyList = () => {
    let theToken = localStorage.getItem("token");
    const url = `${hardBaseUrl}/currency/datatable?page=1&per_page=99&search=&sort=`;
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        setCurrencyList(res.data.data);
      })
      .catch((err) => new Error(err));
  };
  const onChangeFile = e => {
    const name = e.target.name;
    const data = e.target.files[0];
    const object = URL.createObjectURL(data);
    if (name === "photo") {
      setFile(data);
      setObjectUrl(object);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setOpenBackdrop(true);
    const urlAddress = `${hardBaseUrl}/dsp`;
    let theToken = localStorage.getItem("token");
    const headers = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${theToken}`,
      },
    };
    const formData = new FormData();
    formData.append("address", address);
    formData.append("name", dsp);
    formData.append("person_name", nama);
    formData.append("color", color);
    formData.append("link", url);
    formData.append("person_phone", telepon);
    formData.append("person_email", email);
    formData.append("image_logo", file);
    formData.append("is_pay_service", checkedVal);
    formData.append("process_method", processMethod);
    formData.append("country_id", country?.id || "");
    formData.append("dsp_currency_id", currency?.id || "");
    axios
      .post(urlAddress, formData, headers)
      .then(res => {
        if (files !== null) {
          const newFormData = new FormData();
          const url = `${hardBaseUrl}/upload-dsrf/example/create`;
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          };
          newFormData.append("dsp_id", res.data.data.dsp_id);
          files.map(val => {
            newFormData.append("files", val);
          });
          axios
            .post(url, newFormData, config)
            .then(() => {
              setOpenBackdrop(false);
              setOpenDialog(false);
              Swal.fire({
                title: "Success",
                text: "Data added successfully",
                icon: "success",
                confirmButtonText: "Ok",
              }).then(result => {
                if (result.value) {
                  history.push("/admin/parameter/dsp-admin");
                }
              });
            })
            .catch(() => {
              setOpenBackdrop(false);
              setOpenDialog(false);
              Swal.fire({
                title: "Oops…",
                text: "Document failed to upload",
                icon: "error",
                confirmButtonText: "Ok",
              });
            });
        } else {
          setOpenBackdrop(false);
          setOpenDialog(false);
          Swal.fire({
            title: "Success",
            text: "Data added successfully",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.value) {
              history.push("/admin/parameter/dsp-admin");
            }
          });
        }
      })
      .catch(err => {
        setOpenBackdrop(false);
        Swal.fire({
          title: "Oops…",
          text: `${err.response.data.errors[0].message}.`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }).finally(() => {
        setLoading(false);
        setOpenBackdrop(false);
      });
  };
  const handleChecked = () => {
    setcheckedVal(prev => !prev);
  };
  const handleChangePhotoButton = e => {
    e.preventDefault();
    inputFile.current.click();
  };
  const handleCloseImportDialog = () => setOpenDialog(false);
  const handleImportDokumen = e => {
    e.preventDefault();
    setOpenDialog(false);
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleChangeProcessMethod = e => {
    setProcessMethod(e.target.value);
  };
  return (
    <Box className={classes.root}>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Page title="Add DSP">
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>Add DSP</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <a href="/admin/parameter/publisher">Master</a>
                <a href="/admin/parameter/dsp-admin">DSP</a>
                <Typography>Add DSP</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    DSP Information
                  </Typography>
                </ThemeProvider>
                <Box className={classes.divContainer}>
                  <Box display="flex">
                    <Typography className={classes.label}>
                      DSP Name
                    </Typography>
                    <Typography className={classes.labelRequired}>
                      *
                    </Typography>
                  </Box>
                  <TextField
                    className={(classes.myTextField, classes.mt5)}
                    fullWidth
                    onChange={onChange}
                    disabled={loading}
                    id={"dsp"}
                    name="dsp"
                    type="text"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.inputFields },
                    }}
                  />
                  <Box className={classes.mt16}>
                    <Box display="flex">
                      <Typography className={classes.label}>
                        DSP Logo
                      </Typography>
                      <Typography className={classes.labelRequired}>
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <Grid className={classes.mt5}>
                    <Grid item xs={8}>
                      <Box className={classes.flex}>
                        {file === null ? (
                          <Box className={classes.imgContainer}>
                            <InnoImage
                              src={UnggahLogo}
                              alt="Preview"
                              className={classes.imgPreview}
                              styleImageContainer={{
                                boxShadow: "none",
                                padding: 0,
                                position: "relative",
                                top: "45px",
                                left: "35px",
                              }}
                              styleImage={{
                                maxWidth: "150px",
                                maxHeight: "150px",
                              }}
                            />
                          </Box>
                        ) : (
                          <InnoImage
                            src={objectUrl}
                            alt="Preview"
                            className={classes.imgPreview}
                            styleImageContainer={{
                              boxShadow: "none",
                              padding: 0,
                              border: "1px solid #D1D5DC",
                              borderRadius: "6px",
                            }}
                            styleImage={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                            }}
                          />
                        )}
                        <input
                          onChange={onChangeFile}
                          ref={inputFile}
                          className={classes.displayNone}
                          type="file"
                          name="photo"
                        />
                        <PrimaryButton
                          onClick={handleChangePhotoButton}
                          label="Change Logo"
                          disabled={loading}
                          loading={loading}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className={classes.mt16} display="flex" gap="1rem">
                    <Typography className={classes.label}>
                      Identity Color
                    </Typography>
                    <Typography className={classes.labelRequired}>
                      *
                    </Typography>
                  </Box>
                  <Box className={classes.textCenter}>
                    <Grid>
                      <Grid item xs={8}>
                        <Box className={classes.colorPickerArea}>
                          <TextField
                            fullWidth={true}
                            name="color"
                            onChange={onChange}
                            value={color}
                            variant="outlined"
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box className={colorPrev.changeColor}></Box>
                                  <Divider
                                    className={classes.colorDivider}
                                    orientation="vertical"
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ExpandMore />
                                </InputAdornment>
                              ),
                            }}
                            onClick={() =>
                              !loading && setShowColorPicker(
                                showColorPicker => !showColorPicker
                              )
                            }
                          />
                          {showColorPicker && (
                            <PhotoshopPicker
                              color={color}
                              onAccept={() =>
                                setShowColorPicker(
                                  showColorPicker => !showColorPicker
                                )
                              }
                              onCancel={() =>
                                setShowColorPicker(
                                  showColorPicker => !showColorPicker
                                )
                              }
                              onChange={updateColor => setColor(updateColor.hex)}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.mt16}>
                    <Box display="flex">
                      <Typography className={classes.label}>
                        URL Endpoint
                      </Typography>
                      <Typography className={classes.labelRequired}>
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    onChange={onChange}
                    disabled={loading}
                    id="url"
                    name="url"
                    type="text"
                    variant="outlined"
                    className={(classes.myTextField, classes.mt5)}
                    InputProps={{
                      classes: { input: classes.inputFieldsUrl },
                    }}
                  />
                  <Box className={classes.mt16}>
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.label} htmlFor="url">
                        Process Method Flag
                      </Typography>
                    </ThemeProvider>
                  </Box>
                  <Box>
                    <FormControl
                      variant="outlined"
                      className={classes.selectFormControl}
                    >
                      <Select
                        labelId="label-select-process-method"
                        id="select-process-method"
                        value={processMethod}
                        disabled={loading}
                        onChange={handleChangeProcessMethod}
                      >
                        {processMethodList.map((item, index) => {
                          return (
                            <MenuItem
                              key={`${item.id}-${index}`}
                              value={`${item.id}`}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className={classes.mt16}>
                    <Box display="flex">
                      <Typography className={classes.label}>
                        Country
                      </Typography>
                      <Typography className={classes.labelRequired}>
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(_, country) => setCountry(country)}
                        options={countryList}
                        disabled={loading}
                        getOptionLabel={option => option?.country_name}
                        value={country}
                        className={classes.autoComplete}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Country"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box className={classes.mt16}>
                    <Box display="flex">
                      <Typography className={classes.label}>
                        Currency
                      </Typography>
                      <Typography className={classes.labelRequired}>
                        *
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth={true}
                    >
                      <Autocomplete
                        onChange={(_, newValue) => {
                          setCurrency(newValue);
                        }}
                        options={currencyList}
                        getOptionLabel={option => option?.currency_iso_code}
                        className={classes.autoComplete}
                        disabled={loading}
                        value={currency}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Currency"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    Person In Charge
                  </Typography>
                </ThemeProvider>
                <Box className={classes.divContainer}>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Typography className={classes.label}>
                        The name of the person in charge
                      </Typography>
                      <Typography className={classes.labelRequired}>
                        *
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="nama"
                      name="nama"
                      type="text"
                      variant="outlined"
                      className={(classes.myTextField, classes.mt5)}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.mt16}>
                      <Box display="flex">
                        <Typography className={classes.label}>
                          Email
                        </Typography>
                        <Typography className={classes.labelRequired}>
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      onChange={onChange}
                      id="email"
                      name="email"
                      type="email"
                      variant="outlined"
                      className={(classes.myTextField, classes.mt5)}
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.mt16}>
                      <Box display="flex">
                        <Typography className={classes.label}>
                          Phone Number
                        </Typography>
                        <Typography className={classes.labelRequired}>
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      className={(classes.myTextField, classes.mt5)}
                      onChange={onChange}
                      id="telepon"
                      name="telepon"
                      type="tel"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={classes.mt16}>
                      <Box display="flex">
                        <Typography className={classes.label}>
                          Address
                        </Typography>
                        <Typography className={classes.labelRequired}>
                          *
                        </Typography>
                      </Box>
                    </Box>
                    <TextField
                      className={classes.mt5}
                      fullWidth
                      onChange={onChange}
                      id="address"
                      name="address"
                      margin="normal"
                      type="text"
                      variant="outlined"
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Box className={classes.mt16}>
                    <FormGroup className={classes.ml10}>
                      <FormControlLabel
                        control={
                          <Switch
                            disableRipple
                            classes={{
                              root: classes.rootSwitch,
                              switchBase: classes.switchBase,
                              thumb: classes.thumb,
                              track: classes.track,
                              checked: classes.checked,
                            }}
                            checked={checkedVal}
                            onChange={handleChecked}
                          />
                        }
                        label="DSP pay for service"
                      />
                    </FormGroup>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <PrimaryButton
                  onClick={onSubmit}
                  label="Save"
                  loading={loading}
                  disabled={loading}
                  color={loading ? "grey" : "black"}
                />
              </Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes.title}>
                    DSP Template
                  </Typography>
                </ThemeProvider>
                <Box className={classes.divContainer}>
                  <PrimaryButton
                    onClick={() => setOpenDialog(true)}
                    label="Upload Template"
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
          <DialogUploadDokumen
            open={openDialog}
            onClose={handleCloseImportDialog}
            getInputProps={getInputProps}
            getRootProps={getRootProps({ style })}
            isDragActive={isDragActive}
            files={files}
            onSubmit={handleImportDokumen}
            handleRemoveFile={onRemoveFile}
          />
        </Container>
      </Page>
    </Box>
  );
};
export default TambahDsp;
