import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  BalanceCard,
  CurrencyNumberInput,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { globalStyles } from "styles";
import { getCookie, getErrors } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";

const PenciptaAddPayment = () => {
  const classes = globalStyles();
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [balanceNumber, setBalanceNumber] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [payload, setPayload] = useState({
    date: moment(),
    value: 0,
    note: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeValue = value => {
    if (value > balanceNumber) {
      ModalError("Payment value is greater than remaining balance");
      handleChangePayload(0, "value");
      setRemainingBalance(balanceNumber);
    } else {
      handleChangePayload(value, "value");
      setRemainingBalance(balanceNumber - value);
    }
  };
  const handleChangeRemainingBalance = () => {
    setRemainingBalance(balanceNumber - payload?.value);
  };

  const getPaymentBalance = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/get-balance`,
        { headers }
      );
      const resBalance = res?.data?.data?.balance_number;
      setBalanceNumber(resBalance);
      setRemainingBalance(resBalance);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const handleSubmit = async () => {
    const modifiedPayload = {
      ...payload,
      date: payload?.date.format("YYYY-MM-DD"),
    };
    try {
      setLoadingPage(true);
      await axios.post(
        `${hardBaseUrl}/composer/transaction/payment`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess("Successfully Create Transaction").then(() =>
        history.goBack()
      );
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getPaymentBalance();
  }, []);

  return (
    <Page className={classes.root} title="Payment">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Payment Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <SectionLabel
            title="Create Transaction"
            subTitle="Feature for making payment transactions to composer"
            mb="16px"
          />
          <BalanceCard value={balanceNumber} />
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <FormLabel label="Date" />
                <DatePicker
                  value={payload?.date}
                  onChange={date => handleChangePayload(date, "date")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Publisher" />
                <TextInput value={userLogin?.publisher?.name} disabled />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Value" />
                <CurrencyNumberInput
                  value={payload?.value}
                  onChange={handleChangeValue}
                  startAdornment="Rp"
                  decimalScale={2}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel label="Remaining Balance" />
                <CurrencyNumberInput
                  value={remainingBalance}
                  onChange={handleChangeRemainingBalance}
                  startAdornment="Rp"
                  decimalScale={2}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel label="Notes" />
                <TextInput
                  placeholder="Notes"
                  value={payload?.note}
                  onChange={e => handleChangePayload(e?.target?.value, "note")}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="right">
            <PrimaryButton label="Add" onClick={handleSubmit} />
          </Grid>
        </Container>
      )}
    </Page>
  );
};
const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/payment",
  },
  {
    label: "Payment",
    link: "/pencipta/payment",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];

export default PenciptaAddPayment;
