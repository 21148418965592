import { UserOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";
import searchImage2 from "../assets/img/search2.png";
import { hardBaseUrl, hardSubDomain } from "../services/urlConstant";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const { Option, OptGroup } = Select;

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  containerLoading: { display: "flex", justifyContent: "center" },
}));
export default function SearchInput() {
  const classes = useStyles();
  const history = useHistory();
  const [options, setOption] = useState({
    artists: [],
    composers: [],
    songs: [],
  });
  const [open, setOpen] = useState(false);
  const [songFiltered, setSongFiltered] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [composerFiltered, setComposerFiltered] = useState(false);
  const [artisFiltered, setArtisFiltered] = useState(false);
  const handleSearch = async val => {
    setOpen(true);
    setLoadingSearch(true);
    const url =
      hardBaseUrl +
      `/search/song/suggestion?search=${val}&subdomain=${hardSubDomain}`;
    try {
      const response = await Axios.get(url);
      setOption(response.data.data);
      setLoadingSearch(false);
    } catch (error) {
      new Error(error);
      setLoadingSearch(false);
    }
  };
  let children = (
    <>
      {options?.songs?.length > 0 && (
        <OptGroup label="Song">
          {songFiltered
            ? options.songs.map((res, i) => {
                return (
                  <Option value={res} key={res + i} field="songs">
                    <i
                      className="fa fa-music"
                      style={{ marginRight: "10px", color: "#687083" }}
                    />{" "}
                    {res}
                  </Option>
                );
              })
            : options.songs.map((res, i) => {
                if (i < 3) {
                  return (
                    <Option value={res} key={"song" + res + i} field="songs">
                      <i
                        className="fa fa-music"
                        style={{ marginRight: "10px", color: "#687083" }}
                      />{" "}
                      {res}
                    </Option>
                  );
                } else {
                  return null;
                }
              })}
          {options?.songs?.length > 3 && (
            <span style={{ marginLeft: "25px" }} value="song">
              Show {songFiltered ? "less" : "more"}
            </span>
          )}
        </OptGroup>
      )}
      {options?.composers?.length > 0 && (
        <OptGroup label="Composer">
          {composerFiltered
            ? options.composers.map((res, i) => {
                return (
                  <Option value={res} key={res + i} field="composers">
                    <UserOutlined
                      style={{ marginRight: "10px", color: "#687083" }}
                    />{" "}
                    {res}
                  </Option>
                );
              })
            : options.composers.map((res, i) => {
                if (i < 3) {
                  return (
                    <Option
                      value={res}
                      key={"composer" + res + i}
                      field="composers"
                    >
                      <UserOutlined
                        style={{ marginRight: "10px", color: "#687083" }}
                      />{" "}
                      {res}
                    </Option>
                  );
                } else {
                  return null;
                }
              })}
          {options?.composers?.length > 3 && (
            <span style={{ marginLeft: "25px" }} value="composer">
              Show {composerFiltered ? "less" : "more"}
            </span>
          )}
        </OptGroup>
      )}
      {options?.artists?.length > 0 && (
        <OptGroup label="Artist">
          {artisFiltered
            ? options.artists.map((res, i) => {
                return (
                  <Option value={res} key={res + i} field="artists">
                    <UserOutlined
                      style={{ marginRight: "10px", color: "#687083" }}
                    />{" "}
                    {res}
                  </Option>
                );
              })
            : options.artists.map((res, i) => {
                if (i < 3) {
                  return (
                    <Option value={res} key={res + i} field="artists">
                      <UserOutlined
                        style={{ marginRight: "10px", color: "#687083" }}
                      />{" "}
                      {res}
                    </Option>
                  );
                } else {
                  return null;
                }
              })}
          {options?.artists?.length > 3 && (
            <span style={{ marginLeft: "25px" }} value="artis">
              Show {artisFiltered ? "less" : "more"}
            </span>
          )}
        </OptGroup>
      )}
    </>
  );
  const hanldeSelect = (a, b) => {
    if (a === "song" || a === "composer" || a === "artis") {
      setSongFiltered(!songFiltered);
      b.value === "song"
        ? setSongFiltered(!songFiltered)
        : b.value === "composer"
        ? setComposerFiltered(!composerFiltered)
        : setArtisFiltered(!artisFiltered);
    } else {
      history.push({
        pathname: "/detail-lagu",
        search: `?search=${a}?field=${b.field ? b.field : ""}`,
        state: {
          search: a,
          field: b.field ? b.field : "",
        },
      });
    }
  };
  return (
    <div className="search-wrapper">
      <img src={searchImage2} alt="" />
      <Select
        open={open}
        autoClearSearchValue={true}
        bordered={false}
        listItemHeight={10}
        listHeight={250}
        className="input-search"
        showSearch
        size="large"
        placeholder="Search for a song, singer or composer"
        style={{ width: "100%", height: "35px" }}
        onSearch={handleSearch}
        filterOption={false}
        showArrow={false}
        onBlur={() => setOpen(false)}
        onSelect={hanldeSelect}
      >
        {loadingSearch ? (
          <div className={classes.containerLoading}>
            <LinearProgress />
          </div>
        ) : (
          children
        )}
      </Select>
    </div>
  );
}
