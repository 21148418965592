import { Card, Grid, styled } from "@mui/material";
import DollarIcon from "../../../assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg";
import { Typography } from "@material-ui/core";
import { CurrencyDisplay } from "components";
import { globalStyles } from "styles";

const BalanceCard = ({
  title,
  value,
  prefix,
  clientLabel,
  periodLabel,
  minWidth,
}) => {
  const classes = globalStyles();
  return (
    <CustomCard minWidth={minWidth}>
      <Grid
        container
        alignItems="center"
        columnSpacing={2}
        mb={1.5}
        mr={periodLabel && "36px"}
      >
        <Grid item>
          <img src={DollarIcon} alt="$" />
        </Grid>
        <Grid item>
          <Grid container direction="column" rowSpacing={1}>
            <Grid item>
              <Typography className={classes?.balanceTitle}>
                {title || "Balance"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes?.balanceValue}>
                <CurrencyDisplay
                  value={value}
                  decimalScale={2}
                  prefix={`${prefix ? prefix : "Rp"}`}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography className={classes?.balanceTitle}>
            {clientLabel}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes?.balanceTitle}>
            {periodLabel}
          </Typography>
        </Grid>
      </Grid>
    </CustomCard>
  );
};

const CustomCard = styled(Card)(({ minWidth }) => ({
  backgroundColor: "#111827",
  minWidth,
  width: "fit-content",
  padding: "16px 24px",
  borderRadius: "10px",
}));

export default BalanceCard;
