import { Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { emptyText, getErrors } from "utils";

function MasterCountry() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/country/table`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/country/delete/${id}`, {
        headers,
      });
      ModalSuccess("Succesfully delete country").then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Country">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes?.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              my="16px"
            >
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Country"
                  onClick={() =>
                    history.push("/admin/parameter/admin-country/tambah")
                  }
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.per_page}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "per_page")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  tooltipDelete="Delete Country"
                  deleteConfirmationKey={item?.country_name}
                  handleDelete={() => handleDelete(item?.id)}
                  tooltipEdit="Update Country"
                  handleEdit={() =>
                    history.push({
                      pathname: `/admin/parameter/admin-country/edit/${item?.id}`,
                      state: {
                        country: item,
                      },
                    })
                  }
                />
              )}
            />
          </Fragment>
        )}
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/dsp-admin",
  },
  {
    label: "DSP",
    active: true,
  },
];
const columnTable = [
  {
    name: "country_iso_code",
    title: "Country Code",
    renderText: item => emptyText(item),
  },
  {
    name: "country_name",
    title: "Country Name",
    renderText: item => emptyText(item),
  },
  {
    name: "currency",
    title: "Currency",
    renderText: item => emptyText(item?.currency_iso_code),
  },
];
export default MasterCountry;
