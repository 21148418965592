import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTable } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { emptyText, getErrors } from "utils";

function MasterAssociation() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/association/index`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleActivateUser = async (id, restore) => {
    try {
      setLoadingPage(true);
      if (restore)
        await axios.put(
          `${hardBaseUrl}/association/recovery/${id}`,
          {},
          { headers }
        );
      else
        await axios.delete(`${hardBaseUrl}/association/delete/${id}`, {
          headers,
        });

      ModalSuccess(
        `Successfully ${restore ? "Restore" : "Deactivate"} Association`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Fragment>
      <Page className={classes.root} title="Association">
        <Container maxWidth={false}>
          <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes?.flatDivider} />
          {loadingPage ? (
            <SkeletonComponent variant="wave" />
          ) : (
            <Fragment>
              <Grid container justifyContent="space-between" my="16px">
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    value={queryParams?.search}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "search")
                    }
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Add Association"
                    startIcon={<Add />}
                    onClick={() =>
                      history.push("/admin/parameter/association/add")
                    }
                    size="large"
                  />
                </Grid>
              </Grid>
              <InnoTable
                isLoading={false}
                columns={columnTable}
                items={dataTable}
                page={queryParams?.page}
                rowsPerPage={queryParams?.per_page}
                totalPage={tableTotalPage}
                handleChangePage={(_, page) =>
                  handleChangeQueryParams(page, "page")
                }
                handleChangeRowsPerPage={e =>
                  handleChangeQueryParams(e?.target?.value, "per_page")
                }
                isHaveAction
                renderAction={item => (
                  <TableAction
                    deleteConfirmation
                    deleteConfirmationTitle="Deactivate Association"
                    deleteConfirmationContent={`Are you sure you want to deactivate ${item?.name} ?`}
                    handleDelete={
                      item?.is_active_flag
                        ? () => handleActivateUser(item?.association_id)
                        : null
                    }
                    restoreConfirmation
                    restoreConfirmationTitle="Restore Association"
                    restoreConfirmationContent={`Are you sure you want to restore ${item?.name} ?`}
                    handleRestore={
                      !item?.is_active_flag
                        ? () => handleActivateUser(item?.association_id, true)
                        : null
                    }
                    handleEdit={() =>
                      history.push({
                        pathname: `/admin/parameter/association/${item.association_id}`,
                        state: { customer: item },
                      })
                    }
                  />
                )}
              />
            </Fragment>
          )}
        </Container>
      </Page>
    </Fragment>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/association",
  },
  {
    label: "Association",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "Association Name",
    renderText: item => emptyText(item),
  },
  {
    name: "pic",
    title: "Person In Charge",
    renderText: item => emptyText(item),
  },
  {
    name: "email",
    title: "Email",
    renderText: item => emptyText(item),
  },
  {
    name: "phone_number",
    title: "Phone",
    renderText: item => emptyText(item),
  },
];
export default MasterAssociation;
