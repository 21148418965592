import { Container, Divider } from "@material-ui/core";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import {
  BalanceCard,
  CurrencyDisplay,
  CurrencyNumberInput,
  CustomTable,
  ImportDocumentModal,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SkeletonComponent,
  StatusChip,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { formatDate, getErrors, wordCapitalize } from "utils";

const DetailLoanPencipta = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});
  const [modalPayment, setModalPayment] = useState(false);
  const [balance, setBalance] = useState(null);
  const [initTable, setInitTable] = useState([]);
  const [money, setMoney] = useState("");
  const [loanDetail, setLoanDetails] = useState([]);
  const [loanDetailLength, setLoanDetailLength] = useState(0);
  const [enableAddButton, setEnableAddButton] = useState(false);

  const detailLoan = scaffolddetailLoan({ pageDetail });

  const oneTable = {
    ins_number: 1,
    due_date: "aaaa",
    installment_value: 0,
    status_installment: "Unpaid",
    show_action: true,
  };

  const createNowDate = () => {
    const yyy = moment().format("D MMMM YYYY");

    return yyy;
  };

  const getDetail = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/loan/${id}`,
        { headers }
      );
      const { data } = res?.data;
      setLoanDetailLength(data?.loan_details?.length);
      setLoanDetails(data?.loan_details);
      setPageDetail(data);
      if (data?.loan_details?.length > 0) {
        setEnableAddButton(true);
        let items = data.loan_details;
        let newArr = [];
        items.forEach(item => {
          let newObj = {
            ins_number: item.installment_number,
            due_date: item.date,

            installment_value: item.repayment_amount,
            status_installment: item.payment_status,
            show_action: false,
          };
          newArr.push(newObj);
        });

        setInitTable(newArr);
      } else {
        setEnableAddButton(false);
        let newTab = {
          ...oneTable,
          due_date: createNowDate(),
          ins_number: data?.loan_details?.length + 1,
        };

        let currentDetail = data?.loan_details;
        currentDetail.push(newTab);
        setInitTable(currentDetail);
      }
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getBalance = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/composer/transaction/payment/get-balance`,
        { headers }
      );
      setBalance(res?.data?.data?.balance_string);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const fetchData = async () => {
    setLoadingPage(true);
    const promises = [getDetail(), getBalance()];
    try {
      await Promise.all(promises);
    } catch (error) {
      ModalError(error, "Error fetching data");
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const uploadIns = async files => {
    setModalPayment(false);
    let numMoney = money.replaceAll(",", "");
    const formData = new FormData();
    formData.append("id", id);
    files.map(val => {
      formData.append("documents", val);
    });
    formData.append("repayment_amount", Number(numMoney));
    try {
      await axios.post(
        `${hardBaseUrl}/composer/transaction/loan/repayment`,
        formData,
        { headers }
      );
      ModalSuccess().then(() => history.go(0));
    } catch (error) {
      ModalError(error, "Error fetching data");
    }
  };

  const addNewRow = () => {
    setEnableAddButton(false);
    let items = loanDetail;
    let newArr = [];
    items.forEach(item => {
      let newValueOfMoney = item.repayment_amount;
      let newObj = {
        ins_number: item.installment_number,
        due_date: item.date,

        installment_value: newValueOfMoney,
        status_installment: item.payment_status,
        show_action: false,
      };
      newArr.push(newObj);
    });
    let newTab = {
      ...oneTable,
      due_date: createNowDate(),
      ins_number: loanDetailLength + 1,
    };
    newArr.push(newTab);
    setInitTable(newArr);
  };

  return (
    <Page className={classes.root} title="Loan">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Loan Transaction"
            breadcrumbData={breadcrumbData}
            backButton
          />
          <Divider className={classes.divider} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <SectionLabel
                title="Loan Detail"
                subTitle="This is the detail of the loan transaction"
              />
            </Grid>
            <Grid item>
              <StatusChip
                label={chipLabel(pageDetail?.status)}
                type={chipColor(pageDetail?.status)}
              />
            </Grid>
          </Grid>
          <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
            <Grid container>
              <Grid item xs={6}>
                <Typography fontSize={24} fontWeight={600}>
                  {pageDetail?.composer_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography textAlign="right">
                  {pageDetail?.composer_address ||
                    pageDetail?.publisher_address ||
                    "-"}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes?.divider} />
            <Grid container>
              <Grid item xs={12} md={6}>
                Request Loan to:
                <Typography fontWeight={600}>
                  {pageDetail?.publisher_name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {detailLoan?.map((item, index) => (
                  <Grid container justifyContent="space-between" key={index}>
                    <Grid item>{item?.label}</Grid>
                    <Grid item textAlign="right">
                      {item?.value}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={600}>
                  Notes :{" "}
                  <Typography component="span">{pageDetail?.notes}</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="right">
                Value :
                <Typography fontSize={24} fontWeight={600}>
                  {pageDetail?.transaction_value}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {pageDetail?.status === "active" && (
            <Grid container columnSpacing={1}>
              <Grid item>
                <BalanceCard
                  title="Loan Total"
                  value={pageDetail?.transaction_value}
                />
              </Grid>
              <Grid item>
                <BalanceCard value={balance} />
              </Grid>
            </Grid>
          )}
          {pageDetail?.status === "active" && (
            <Box mt="24px">
              <CustomTable
                columnTable={columnTable({ setModalPayment, money, setMoney })}
                data={initTable}
              />
              <Grid container justifyContent="right" my="16px">
                <PrimaryButton
                  label="Add Payment"
                  onClick={() => addNewRow()}
                  disabled={!enableAddButton}
                />
              </Grid>
            </Box>
          )}
        </Container>
      )}
      {modalPayment && (
        <ImportDocumentModal
          open={modalPayment}
          onClose={() => setModalPayment(false)}
          modalTitle="Upload Payment Proof"
          onSubmit={uploadIns}
        />
      )}
    </Page>
  );
};

const chipLabel = status =>
  status === "waiting_for_approval"
    ? "Waiting For Approval"
    : wordCapitalize(status);
const chipColor = status =>
  status === "waiting_for_approval"
    ? "warning"
    : status === "rejected"
    ? "error"
    : "success";
const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/loan/",
  },
  {
    label: "Loan",
    link: "/pencipta/loan/",
  },
  {
    label: "Loan Detail",
    active: true,
  },
];
const scaffolddetailLoan = ({ pageDetail }) => [
  { label: "Date:", value: formatDate(pageDetail?.date) },
  { label: "Composer:", value: pageDetail?.composer_name || "-" },
  { label: "Tenor:", value: pageDetail?.tenor || "-" },
  { label: "Value:", value: pageDetail?.transaction_value || "-" },
];
const columnTable = ({ setModalPayment, money, setMoney }) => [
  {
    name: "ins_number",
    title: "Installment Number",
  },
  {
    name: "due_date",
    title: "Due Date",
    renderText: item => formatDate(item),
  },
  {
    name: "all",
    title: "Installment Value",
    headerAlign: "right",
    renderText: item =>
      item?.status_installment === "paid" ? (
        <CurrencyDisplay value={item?.installment_value} />
      ) : (
        <CurrencyNumberInput
          value={money}
          onChange={value => setMoney(value)}
          startAdornment="Rp "
        />
      ),
  },
  {
    name: "status_installment",
    title: "Status",
  },
  {
    name: "all",
    title: "Action",
    renderText: item => (
      <PrimaryButton
        label="Pay"
        disabled={!item.show_action}
        onClick={() => item.show_action && setModalPayment(true)}
      />
    ),
  },
];
export default DetailLoanPencipta;
