import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { emptyText, getErrors } from "utils";
import ModalFormUserPublisher from "./components/ModalFormUserPublisher";

function UserPublisher() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    role_id: "",
    type: "publisher",
  });
  const [modalVisible, setModalVisible] = useState(false);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const getOptionRole = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/role?type=publisher`, {
        headers,
      });
      setOptionRole(res?.data?.data);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/user`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleActivateUser = async (id, restore) => {
    try {
      setLoadingPage(true);
      if (restore)
        await axios.put(`${hardBaseUrl}/user-recovery/${id}`, {}, { headers });
      else
        await axios.delete(`${hardBaseUrl}/user/${id}`, {
          headers,
        });

      ModalSuccess(
        `Successfully ${restore ? "Restore" : "Deactivate"} User Publisher`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionRole();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="User Publisher">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid container justifyContent="space-between" my="16px">
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item>
                    <SelectInput
                      label="Role"
                      options={optionRole}
                      optionKey="role_id"
                      optionLabel="name"
                      value={queryParams?.role_id || ""}
                      onChange={e =>
                        handleChangeQueryParams(e?.target?.value, "role_id")
                      }
                      placeholder="All Role"
                      width={200}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      onClick={() => setModalVisible(true)}
                      label="Add User Publisher"
                      startIcon={<Add />}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  deleteConfirmationTitle="Deactivate User Publisher"
                  deleteConfirmationContent={`Are you sure you want to deactivate ${item?.name} ?`}
                  handleDelete={
                    item?.is_active_flag
                      ? () => handleActivateUser(item?.user_id)
                      : null
                  }
                  restoreConfirmation
                  restoreConfirmationTitle="Restore User Publisher"
                  restoreConfirmationContent={`Are you sure you want to restore ${item?.name} ?`}
                  handleRestore={
                    !item?.is_active_flag
                      ? () => handleActivateUser(item?.user_id, true)
                      : null
                  }
                />
              )}
            />
          </Fragment>
        )}

        <ModalFormUserPublisher
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          optionRole={optionRole}
          getData={getDataTable}
        />
      </Container>
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/user-publisher",
  },
  {
    label: "User Publisher",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "User Name",
  },
  {
    name: "all",
    title: "Role",
    renderText: item => emptyText(item?.role?.name),
  },
  {
    name: "all",
    title: "Publisher",
    renderText: item => emptyText(item?.publisher?.name),
  },
  {
    name: "phone",
    title: "Phone Number",
  },
  {
    name: "email",
    title: "Email",
  },
];

export default UserPublisher;
