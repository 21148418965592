import { Box, Typography } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import moment from "moment-timezone";
import { currencyFormatting, numberFormatting } from "utils";

const SongUsageTrendChart = ({
  chartData,
  onAxisClick,
  preview,
  tooltipTrigger,
  ...rest
}) => {
  const monthsArray = Array.from({ length: 12 }, (_, i) =>
    moment({ year: moment().year(), month: i }).format("MMMM")
  );
  const emptyChart = !chartData?.datasets?.length;
  return (
    <Box width="100%">
      <LineChart
        xAxis={[
          {
            scaleType: "point",
            data: emptyChart ? monthsArray : chartData?.labels,
            valueFormatter: (code, context) =>
              context.location === "tick" ? (
                code
              ) : (
                <Typography variant="h6">
                  {moment(code, "MMM YY").format("MMMM YY")}
                </Typography>
              ),
          },
        ]}
        yAxis={
          emptyChart
            ? []
            : [
                {
                  valueFormatter: value =>
                    value >= 1000000000000
                      ? `${value / 1000000000000}T`
                      : value >= 1000000000
                      ? `${value / 1000000000}B`
                      : value >= 1000000
                      ? `${value / 1000000}M`
                      : value >= 1000
                      ? `${value / 1000}k`
                      : value.toLocaleString(),
                  disableTicks: true,
                },
              ]
        }
        series={
          emptyChart
            ? []
            : chartData?.datasets?.map(series => ({
                ...series,
                valueFormatter: (value, { dataIndex }) => {
                  return (
                    <Box py={2}>
                      <Typography fontWeight={500}>
                        Revenue :{" "}
                        {currencyFormatting({
                          value,
                        })}
                      </Typography>
                      <Typography>
                        Traffic :{" "}
                        {numberFormatting({
                          value: series?.listener?.[dataIndex],
                        })}
                      </Typography>
                    </Box>
                  );
                },
              }))
        }
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        grid={{ vertical: !emptyChart, horizontal: !emptyChart }}
        onAxisClick={onAxisClick ? onAxisClick : null}
        tooltip={{
          trigger: emptyChart
            ? "none"
            : tooltipTrigger || (preview ? "none" : "axis"),
        }}
        axisHighlight={{
          x: preview || emptyChart ? "none" : "line",
          y: preview || emptyChart ? "none" : "line",
        }}
        height={600}
        {...rest}
      />
    </Box>
  );
};

export default SongUsageTrendChart;
