export const getSubdomain = () => {
  const hostname = window.location.hostname;

  // Pola regex untuk validasi domain dan subdomain
  const mpisRegex = /^(dev\.staging\.mpis\.id|staging\.mpis\.id|mpis\.id|localhost)$/;
  const carisRegex = /^(.*\.)?(staging\.caris\.id|caris\.id|localhost)$/;

  if (mpisRegex.test(hostname)) {
    // Jika termasuk kategori MPIS
    localStorage.setItem("subDomain", "mpis");
    localStorage.setItem("typeWeb", "mpis");
    return "mpis";
  } else if (carisRegex.test(hostname)) {
    // Jika termasuk kategori CARIS
    const parts = hostname.split(".");
    const subDomain = parts.length > 2 ? parts[0] : "caris"; // Ambil subdomain atau default ke "caris"
    localStorage.setItem("subDomain", subDomain);
    localStorage.setItem("typeWeb", "caris");
    return subDomain;
  } else {
    // Default behavior jika hostname tidak dikenali
    console.warn("Hostname tidak dikenali:", hostname);
    localStorage.setItem("subDomain", "unknown");
    localStorage.setItem("typeWeb", "unknown");
    return "unknown";
  }
};

export default { getSubdomain };
