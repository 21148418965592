import { Chip, Container, Divider } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import { Box, Grid } from "@mui/material";
import { getErrors } from "utils";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import {
  ArrayChip,
  CurrencyDisplay,
  ModalError,
  Page,
  PrimaryButton,
  SecondaryButton,
  SongUsageTrendSummarytCard,
} from "components";
import html2canvas from "html2canvas";
import { InnoTableV2 } from "inno-ui";
import { jsPDF } from "jspdf";
import { HeaderTitle } from "layouts";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { globalStyles } from "styles";
import { getTotalPage } from "lib";
import { hardBaseUrl } from "services/urlConstant";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PrintReportReview = () => {
  const classes = globalStyles();
  const history = useHistory();
  const { state } = useLocation();
  const [dataTable, setDataTable] = useState([]);
  const { chartReport, queryParams, dspName, dspColor, dataSet } = state;
  const [queryParamsPrint, setQueryParamsPrint] = useState({
    page: queryParams.page,
    size: queryParams.size,
    search: queryParams.search,
    sort: queryParams.sort,
    dsp_id: queryParams.dsp_id,
    publisher_id: queryParams.publisher_id,
    month: queryParams.month,
    year: queryParams.year,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const inputRef = useRef(null);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleChangeQueryParams = (value, key) => {
    setQueryParamsPrint(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const handlePrintPDF = () => {
    html2canvas(inputRef.current).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      pdf.height = "600";
      pdf.width = "800";
      pdf.addImage(imgData, "PNG", 0, 0, 450, 500);
      pdf.save("download.pdf");
    });
  };
  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    plugins: {
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const getDataTable = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/publisher/song-usage`, {
        headers,
        params: queryParamsPrint,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParamsPrint?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };

  useEffect(() => {
    getDataTable();
  }, [queryParamsPrint]);
  return (
    <Page className={classes.root} title="Song Usage Review">
      <Container maxWidth={false}>
        <div ref={inputRef}>
          <HeaderTitle title="Review of Song Usage" />
          <Divider className={classes.divider} />
          <Box
            border="1px solid #ebebeb"
            borderRadius="5px"
            padding="50px 20px"
          >
            <Box display="flex" justifyContent="start" marginBottom="20px">
              {dspName && (
                <Chip
                  size="small"
                  label={dspName}
                  icon={
                    <FiberManualRecordIcon
                      className={classes?.chipIndicator}
                      style={{ color: dspColor }}
                    />
                  }
                  style={{
                    color: dspColor,
                    backgroundColor: `${dspColor}10`,
                  }}
                />
              )}
            </Box>
            <Bar options={options} data={dataSet} plugins={[]} />
          </Box>
          <SongUsageTrendSummarytCard chartReport={chartReport} />
          <Box my="16px">
            <InnoTableV2
              isLoading={false}
              items={dataTable || []}
              columns={columnTable}
              page={queryParamsPrint?.page}
              rowsPerPage={queryParamsPrint?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
            />
          </Box>
        </div>
        <Grid container justifyContent="right" columnSpacing={1}>
          <Grid item>
            <SecondaryButton label="Back" onClick={() => history.goBack()} />
          </Grid>
          <Grid item>
            <PrimaryButton label="Print Report" onClick={handlePrintPDF} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
const columnTable = [
  {
    name: "title",
    title: "Song Title",
    renderText: item => item || "-",
  },
  {
    name: "composer_names",
    title: "Composer/Author",
    renderText: item => <ArrayChip list={item} max={3} />,
  },
  {
    name: "listener",
    title: "Traffic",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} />,
  },
  {
    name: "royalty",
    title: "Revenue",
    headerAlign: "right",
    renderText: item => <CurrencyDisplay value={item} prefix="Rp " />,
  },
];

export default PrintReportReview;
