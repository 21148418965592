import { BackupOutlined, CloseOutlined } from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  CurrencyNumberInput,
  FormLabel,
  ModalError,
  PrimaryButton,
  PrimaryIconButton,
  SecondaryButton,
} from "components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { formatFileSize, getErrors, stringTruncate } from "utils";
import DocumentVector from "../../../assets/image-public/images/vector-doc-icon.png";
import TrashVector from "../../../assets/img/trashVector.svg";

const ImportDocumentModal = ({
  modalTitle,
  open,
  onClose,
  onSubmit,
  exchangeRate,
  loadingButton,
  fileType,
  multiple,
  downloadFile,
  onChangePublisher,
}) => {
  const classes = globalStyles();
  const dialogRef = useRef();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [payload, setPayload] = useState(0);
  const [selectedPublisher, setSelectedPublisher] = useState("");
  const [documentFiles, setDocumentFiles] = useState([]);
  const [downloadedFile, setDownloadedFile] = useState("");
  const [optionPublisher, setOptionPublisher] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const onDrop = useCallback(acceptedFiles => {
    multiple
      ? setDocumentFiles(prev => [...prev, ...acceptedFiles])
      : setDocumentFiles(acceptedFiles);
  }, []);
  const handleRemoveFile = idx => {
    setDocumentFiles(prevFiles => prevFiles.filter((_, i) => i !== idx));
  };

  const handleSubmit = () => {
    onSubmit(documentFiles, payload);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileType,
    multiple: multiple,
  });
  const downloadTemplate = async () => {
    try {
      const res = await axios.get(
        `${hardBaseUrl}/publisher/song/download-template-catalogue`,
        { headers }
      );
      setDownloadedFile(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    }
  };
  const getOptionPublisher = async () => {
    try {
      setLoadingFilter(true);
      const res = await axios.get(`${hardBaseUrl}/publisher`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const newOptions = data?.map(item => ({
        ...item,
        id: item?.publisher_id,
      }));
      setOptionPublisher(prev =>
        queryParams.page === 1 ? newOptions : [...prev, ...newOptions]
      );
      setHasMore(meta?.limit > queryParams?.page);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingFilter(false);
    }
  };

  const dropzoneStyle = useMemo(() => {
    return isDragActive ? classes?.dragActive : "";
  }, [isDragActive, classes.activeStyle]);

  useEffect(() => {
    downloadFile && downloadTemplate();
  }, []);
  useEffect(() => {
    onChangePublisher && getOptionPublisher();
  }, [queryParams]);

  return (
    <Dialog
      ref={dialogRef}
      open={open}
      onClose={!loadingButton && onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={700}>
              {modalTitle || "Import Document"}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={!loadingButton && onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormLabel label="Upload Document" />
        <div
          {...getRootProps({
            className: `${classes.dragFilesContainer} ${dropzoneStyle}`,
          })}
        >
          <input {...getInputProps()} />
          <Box color="grey">
            <BackupOutlined className={classes?.uploadIcon} />
          </Box>
          <Typography>
            {isDragActive
              ? "Drop the files here..."
              : "Drag files or click to upload"}
          </Typography>
        </div>
        <Grid container direction="column" my="16px" rowSpacing={2}>
          {downloadFile && (
            <Grid item>
              <a href={downloadedFile} download>
                <PrimaryButton label="Download Template" />
              </a>
            </Grid>
          )}
          {onChangePublisher && (
            <Grid item>
              <AutoCompleteWithSearch
                label="Publisher"
                options={optionPublisher}
                optionLabel="name"
                loading={loadingFilter}
                value={
                  optionPublisher?.find(
                    option => option.publisher_id === selectedPublisher
                  ) || null
                }
                onChange={item => {
                  const value = item?.publisher_id;
                  setSelectedPublisher(value);
                  onChangePublisher(value);
                }}
                inputValue={queryParams?.search}
                onInputChange={value =>
                  handleChangeQueryParams(value, "search")
                }
                setQueryParams={setQueryParams}
                hasMore={hasMore}
                width="100%"
              />
            </Grid>
          )}
        </Grid>
        <Box mt="16px">
          <FormLabel label="Uploaded File" />
          {documentFiles.length > 0 &&
            documentFiles?.map((item, index) => (
              <Grid
                key={index}
                container
                justifyContent="space-between"
                alignItems="center"
                my={2}
              >
                <Grid item>
                  <Grid item container columnSpacing={2} alignItems="center">
                    <Grid item>
                      <img alt="Logo" src={DocumentVector} />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {stringTruncate(item?.name, 20)}
                      </Typography>
                      <Typography variant="body2">
                        {formatFileSize(item?.size)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <PrimaryIconButton
                    onClick={() => handleRemoveFile(index)}
                    icon={<img alt="delete" src={TrashVector} />}
                  />
                </Grid>
              </Grid>
            ))}
        </Box>
        {exchangeRate && (
          <Box my="16px">
            <FormLabel label="Exchange Rate" />
            <CurrencyNumberInput
              startAdornment="Rp"
              value={payload}
              onChange={value => setPayload(value)}
              decimalScale={2}
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <SecondaryButton
          label="Cancel"
          onClick={onClose}
          disabled={loadingButton}
        />
        <PrimaryButton
          label={loadingButton ? "Uploading" : "Upload"}
          disabled={loadingButton}
          loading={loadingButton}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ImportDocumentModal;
