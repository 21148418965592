import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Axios from "axios";
import frame1 from "../../../assets/img/Frame1.png";
import frame2 from "../../../assets/img/Frame2.png";
import frame3 from "../../../assets/img/Frame3.png";
import frame4 from "../../../assets/img/Frame4.png";
import frame5 from "../../../assets/img/Frame5.png";
import frame6 from "../../../assets/img/Frame6.png";
import Layout from "../../../mpisLandingComponent/Layout";
import { hardBaseUrl, hardSubDomain } from "../../../services/urlConstant";
import "./TentangKami.css";

const useStyles = makeStyles(() => ({
  imageContent: {
    maxWidth: "100%",
    maxHeight: 180,
    objectFit: "contain",
    width: 300
  },
  textContent: {
    color: "#687083"
  },
}));

export default function TentangKami() {
  const classes = useStyles();
  const [dataContent, setDataContent] = useState({
    about_us: "",
    images: [],
    publisher: {},
    our_vision: "",
  });

  const getData = async () => {
    try {
      const response = await Axios.get(
        hardBaseUrl +
        `/landing/publisher/web-content?subdomain${hardSubDomain !== "mpis" &&
        hardSubDomain}`
      );
      if (response.data.message === "success") {
        setDataContent({
          about_us: response.data.data.about_us,
          images: response.data.data.images || [],
          publisher: response.data.data.publisher,
          our_vision: response.data.data.our_vision,
        });

      }
    } catch (error) {
      new Error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const imagesVision = dataContent?.images.filter(({ type }) => type === "our_vision");
  const imagesAboutUs = dataContent?.images.filter(({ type }) => type === "about_us");
  return (
    <Layout>
      <Box className="tentang-kami">
        <Box className="banner">
          <Box
            display="inline-block"
            className="container"
          >
            <Box>
              <h1 style={{ fontSize: "48px", fontWeight: 700 }}>
                About Us
              </h1>
              <p
                style={{ width: "600px", fontSize: "18px", color: "#687083" }}
                dangerouslySetInnerHTML={{
                  __html: dataContent.about_us,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className="gallery">
          <Row style={{ height: "100%" }}>
            <Col span={7} style={{ height: "100%", paddingRight: "18px" }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#E4E7EB",
                  objectFit: "cover",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[0] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[0]
                      .image_name
                    : frame1
                }
                alt=""
              />
            </Col>
            <Col span={10}>
              <Box height="100%" width="100%">
                <Box height="360px" paddingBottom="8px">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[1] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[1].image_name
                        : frame2
                    }
                    alt=""
                  />
                </Box>
                <Box
                  height="190px"
                  paddingTop="8px"
                  display="flex"
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                      marginRight: "8px",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[2] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[2].image_name
                        : frame3
                    }
                    alt=""
                  />
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#E4E7EB",
                      objectFit: "cover",
                      marginLeft: "8px",
                    }}
                    src={
                      dataContent.images?.length > 0 &&
                        dataContent.images.filter(
                          v => v.type === "publisher"
                        )[3] !== undefined
                        ? dataContent.images.filter(
                          v => v.type === "publisher"
                        )[3].image_name
                        : frame4
                    }
                    alt=""
                  />
                </Box>
              </Box>
            </Col>
            <Col span={7} style={{ paddingLeft: "18px" }}>
              <img
                style={{
                  width: "100%",
                  height: "190px",
                  objectFit: "cover",
                  paddingBottom: "8px",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[4] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[4]
                      .image_name
                    : frame5
                }
                alt=""
              />
              <img
                style={{
                  width: "100%",
                  height: "360px",
                  objectFit: "cover",
                  paddingTop: "8px",
                }}
                src={
                  dataContent.images?.length > 0 &&
                    dataContent.images.filter(v => v.type === "publisher")[5] !==
                    undefined
                    ? dataContent.images.filter(v => v.type === "publisher")[5]
                      .image_name
                    : frame6
                }
                alt=""
              />
            </Col>
          </Row>
        </Box>
        <Box
          className="container"
          padding={0}
        >
          <Box className="content">
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              margin="30px 0px"
            >
              <Box gridGap=".5rem" display="grid">
                <Box width={500} height={180}>
                  <img className={classes.imageContent} src={imagesAboutUs[0]?.image_name} alt="image1" />
                </Box>
                <Box width={500} height={180} justifyContent="flex-end" display="flex">
                  <img className={classes.imageContent} src={imagesAboutUs[1]?.image_name} alt="image2" />
                </Box>
              </Box>
              <Box width={585} display="flex" alignItems="center">
                <Box>
                  <h1>About Us</h1>
                  <p
                    className={classes.textContent}
                    dangerouslySetInnerHTML={{
                      __html: dataContent.about_us,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
            >
              <Box width={585} display="flex" alignItems="center">
                <Box>
                  <h1>Our Vision</h1>
                  <p
                    className={classes.textContent}
                    dangerouslySetInnerHTML={{
                      __html: dataContent.our_vision,
                    }}
                  />
                </Box>
              </Box>
              <Box gridGap=".5rem" display="grid">
                <Box width={500} maxHeight={180}>
                  <img className={classes.imageContent} src={imagesVision[0]?.image_name} alt="image1" />
                </Box>
                <Box width={500} maxHeight={180} justifyContent="flex-end" display="flex">
                  <img className={classes.imageContent} src={imagesVision[1]?.image_name} alt="image2" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
