import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { emptyText, getErrors } from "utils";

function Territory() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/admin/territory`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/admin/territory/${id}`, {
        headers,
      });
      ModalSuccess("Succesfully delete society").then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Territory">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes?.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              my="16px"
            >
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Territory"
                  onClick={() => history.push("/admin/parameter/territory/add")}
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  tooltipDelete="Delete Country"
                  deleteConfirmationKey={item?.territory_name}
                  handleDelete={() => handleDelete(item?.id)}
                  tooltipEdit="Update Country"
                  handleEdit={() =>
                    history.push(`/admin/parameter/territory/edit/${item?.id}`)
                  }
                />
              )}
            />
          </Fragment>
        )}
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/territory",
  },
  {
    label: "Territory",
    active: true,
  },
];
const columnTable = [
  {
    name: "territory_name",
    title: "Territory Name",
  },
  {
    name: "territory_type_flag",
    title: "Territory Type",
  },
  {
    name: "territory_code",
    title: "TIS-A",
  },
  {
    name: "territory_ext_code",
    title: "TIS-A-EXT",
    renderText: item => emptyText(item),
  },
  {
    name: "countries",
    title: "Country",
    renderText: item => emptyText(item),
  },
];
export default Territory;
