import { Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { emptyText, getErrors } from "utils";
import ModalFormUserSociety from "./components/ModalFormUserSociety";

function UserSociety() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
    party_type: "society",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [preload, setPreload] = useState();

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/user/datatable`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.per_page);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = async id => {
    try {
      await axios.delete(`${hardBaseUrl}/user/${id}`, {
        headers,
      });
      ModalSuccess("Succesfully delete user society").then(() =>
        getDataTable()
      );
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="User Society">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes?.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              my="16px"
            >
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Country"
                  onClick={() => {
                    setModalVisible(true);
                    setPreload(null);
                  }}
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.per_page}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "per_page")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  tooltipDelete="Delete Country"
                  deleteConfirmationKey={item?.country_name}
                  handleDelete={() => handleDelete(item?.id)}
                  tooltipEdit="Update Country"
                  handleEdit={() => {
                    setModalVisible(true);
                    setPreload({
                      username: item?.name,
                      email: item?.email,
                      phone: item?.phone,
                      role_id: item?.role?.role_id,
                      society_id: item?.party_data?.id,
                    });
                  }}
                />
              )}
            />
          </Fragment>
        )}
        <ModalFormUserSociety
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          preload={preload}
          getData={getDataTable}
        />
      </Container>
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/Grid",
  },
  {
    label: "User Society",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "Username",
  },
  {
    name: "email",
    title: "Email",
  },
  {
    name: "phone",
    title: "Phone",
    renderText: item => emptyText(item),
  },
  {
    name: "party_data",
    title: "Society",
    renderText: item => emptyText(item?.society_name),
  },
];
export default UserSociety;
