import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  AutoCompleteComponent,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  NumberInput,
  Page,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import moment from "moment";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { HeaderTitle } from "layouts";
import {
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { getErrors } from "utils";
import axios from "axios";

const CreateTresshold = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [listComposer, setListComposer] = useState([]);
  const [payload, setPayload] = useState({
    date: moment(),
    composer_id: 0,
    value: 0,
    tresshold: 0,
    description: "",
    is_mandatory: false,
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const getComposerList = async () => {
    setLoadingPage(true);
    await axios
      .get(`${hardBaseUrl}/publisher/transaction-composer`, { headers })
      .then(res => {
        const modifiedData = (res?.data?.data || [])
          ?.filter(({ composer_name }) => composer_name)
          ?.map(item => ({
            label: item.composer_name,
            id: item.composer_id,
          }));
        const uniqueItems = modifiedData.filter(
          (item, index, array) =>
            index === array.findIndex(el => el.label === item.label)
        );
        setListComposer(uniqueItems);
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingPage(false));
  };

  const onSubmit = () => {
    const url = `${hardBaseUrl}/publisher/cost-allocation`;
    const { composer_id, date, tresshold, value } = payload;

    if (!value) {
      ModalError("Please input cost value");
      return;
    }
    if (!tresshold) {
      ModalError("Please input tresshold value");
      return;
    }
    if (!composer_id) {
      ModalError("Please select composer");
      return;
    }

    if (composer_id && value && tresshold) {
      const modifiedPayload = {
        ...payload,
        date: date.format("YYYY-MM-DD"),
      };
      setLoadingPage(true);
      axios
        .post(url, modifiedPayload, { headers })
        .then(() => {
          ModalSuccess("Create Tresshold Success").then(() => {
            history.push("/admin/cost-allocation");
          });
        })
        .catch(error => {
          ModalError(getErrors(error?.response));
        })
        .finally(() => setLoadingPage(false));
    }
  };

  const getTresshold = async () => {
    const url = `${hardBaseUrl}/publisher/cost-allocation/tresshold`;
    setLoadingPage(true);
    try {
      const response = await axios.get(url, { headers });
      const { data } = response?.data;
      handleChangePayload(data.tresshold, "tresshold");
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getTresshold();
    getComposerList();
  }, []);

  return (
    <Page className={classes.root} title="Cost Allocation">
      <Container maxWidth={false}>
        <HeaderTitle title="Cost Allocation" breadcrumbData={breadcrumbData} />
        <Divider
          sx={{
            my: 2,
            borderTop: "1px solid #e1e1e1",
          }}
        />
        <TableTitle>Create Treshold</TableTitle>
        <Typography variant="body2" mb={2}>
          Feature for making tresshold & cost allocation settings
        </Typography>

        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormLabel label="Date" required />
                      <DatePicker
                        value={payload?.date}
                        onChange={date => handleChangePayload(date, "date")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel label="Composer/ Author" required />
                      <AutoCompleteComponent
                        options={listComposer}
                        value={
                          listComposer.find(
                            option => option.id === payload?.composer_id
                          ) || null
                        }
                        onChange={value =>
                          handleChangePayload(value, "composer_id")
                        }
                        size="small"
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel label="Cost Allocation Value" required />
                      <NumberInput
                        value={payload?.value}
                        onChange={event =>
                          handleChangePayload(
                            Number(event?.target?.value),
                            "value"
                          )
                        }
                        startAdornment="Rp"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel label="Treshold" required />
                      <NumberInput
                        value={payload?.tresshold}
                        onChange={event =>
                          handleChangePayload(
                            Number(event?.target?.value),
                            "tresshold"
                          )
                        }
                        endAdornment="%"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormLabel label="Description" />
                      <TextInput
                        placeholder="Description"
                        value={payload?.note}
                        onChange={event =>
                          handleChangePayload(event?.target?.value, "notes")
                        }
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12} px={2}>
                      <FormControlLabel
                        control={
                          <FormCheckbox
                            value={payload?.is_mandatory}
                            onChange={event =>
                              handleChangePayload(
                                event?.target?.checked,
                                "is_mandatory"
                              )
                            }
                          />
                        }
                        label="Is cost distribution required"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        columnSpacing={2}
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <SecondaryButton
                            label="Cancel"
                            onClick={() => window.history.back()}
                            disabled={loadingPage}
                          />
                        </Grid>
                        <Grid item>
                          <PrimaryButton
                            loading={loadingPage}
                            disabled={loadingPage}
                            label="Add"
                            onClick={onSubmit}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
const TableTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
}));
const FormCheckbox = styled(Checkbox)(() => ({
  "&.MuiCheckbox-root": {
    color: "#111827",
    "&.Mui-checked": {
      color: "#111827",
    },
  },
}));
const breadcrumbData = [
  {
    label: "Transaction",
    link: "/admin/loan",
  },
  {
    label: "Cost Allocation",
    link: "/admin/loan",
  },
  {
    label: "Create Treshold",
    active: true,
  },
];
export default CreateTresshold;
