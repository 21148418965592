import Breadcrumbs from "../../components/atoms/Breadcrumbs";
import { ArrowBack } from "@material-ui/icons";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useHistory } from "react-router";

const HeaderTitle = ({ title, breadcrumbData, backButton, alignItems }) => {
  const history = useHistory();
  return (
    <Grid container justifyContent="space-between" alignItems={alignItems || "flex-end"}>
      <Grid item>
        <Grid container alignItems="center" columnSpacing="8px">
          {backButton && (
            <Grid item>
              <Tooltip title="Back">
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Typography fontSize="24px" fontWeight={700}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Breadcrumbs data={breadcrumbData} />
      </Grid>
    </Grid>
  );
};
export default HeaderTitle;
