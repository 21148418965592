import {
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Snackbar,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HeaderTitle } from "layouts";
import Swal from "sweetalert2";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
const fontInter = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    h5: {
      fontWeight: 700,
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  colorPickerArea: {},
  button: {
    textTransform: "none",
    marginTop: "16px",
    backgroundColor: "black",
    color: "white",
    width: "135px",
    height: "40px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  imgPreview: {
    maxHeight: "120px",
  },
  fileUploadArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#6e6e6e",
  },
  formColor: {},
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  inputFieldsUrl: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderRight: "1px solid #D1D5DC",
  },
  inputFieldsTlp: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    borderLeft: "1px solid #D1D5DC",
    paddingLeft: "14px",
  },
  myTextField: {
    height: "44px",
    paddingBottom: "0px",
    paddingTop: "0px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: "500px",
    fontSize: "14px",
    lineHeight: "20px",
    width: "140px",
    height: "20px",
  },
  mt5: {
    marginTop: "5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mt15: {
    marginTop: "15px",
  },
  mt16: {
    marginTop: "16px",
  },
  mt20: {
    marginTop: "20px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mb16: {
    marginBottom: "16px",
  },
  mb20: {
    marginBottom: "20px",
  },
  mr5: {
    marginRight: "5px",
  },
  mr10: {
    marginRight: "10px",
  },
  mr15: {
    marginRight: "15px",
  },
  mr16: {
    marginRight: "16px",
  },
  mr20: {
    marginRight: "20px",
  },
  ml5: {
    marginLeft: "5px",
  },
  ml10: {
    marginLeft: "10px",
  },
  ml15: {
    marginLeft: "15px",
  },
  ml16: {
    marginLeft: "16px",
  },
  ml20: {
    marginLeft: "20px",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  flex: {
    displa: "flex",
  },
  flexCenter: {
    displa: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  required: {
    color: "red"
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
    border: "1px solid grey",
    padding: "8px",
    borderRadius: "8px",
  },
  flexBetween1: {
    display: "flex",
    justifyContent: "space-between",
  },
  displayNone: {
    display: "none",
  },
  divContainer: {
    border: "1px solid #9AA2B1",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
  },
  imgContainer: {
    width: "160px",
    height: "160px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  textCenter: {
    textAlign: "center",
    borderRadius: "6px",
    marginTop: "6px",
  },
  font14: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "20px",
    color: "#111827",
  },
  pointer: {
    cursor: "pointer",
  },
  font18: {
    color: "black",
    fontSize: "18px",
  },
  block415: {
    display: "block",
    width: "415px",
  },
  gap4: {
    display: "flex",
    justifyContent: "right",
    paddingRight: "24px",
  },
}));

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/publisher",
  },
  {
    label: "Association",
    link: "/admin/parameter/association",
  },
];

const EditAsosiasi = props => {
  const { state } = useLocation();
  const classes = useStyles();
  const [loadingPage] = useState(false);
  const [name, setName] = useState("");
  const [transmitterCode, setTransmitterCode] = useState("");
  const [pic, setPic] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar] = useState("success");
  const [message] = useState("This is a message!");

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "pic") {
      setPic(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "transmitterCode") {
      setTransmitterCode(value);
    }
  };
  const getDetail = () => {
    const urlBase = `${hardBaseUrl}/association/detail/${state.customer.association_id}`;
    const theToken = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
    };
    axios
      .get(urlBase, headers)
      .then(res => {
        setName(res?.data?.data?.name);
        setPic(res?.data?.data?.pic);
        setPhone(res?.data?.data?.phone_number);
        setEmail(res?.data?.data?.email);
        setTransmitterCode(res?.data?.data?.association_code);
      })
      .catch(err => {
        return new Error(err);
      });
  };
  useEffect(() => {
    getDetail();
  }, []);
  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };
  const notifError = text =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const onSubmit = e => {
    e.preventDefault();
    if (name === "") {
      return notifError("Association name can't be empty");
    }
    if (pic === "") {
      return notifError("PIC name can't be empty");
    }
    if (email === "") {
      return notifError("Email can't be empty");
    }
    if (phone === "") {
      return notifError("Phone number can't be empty");
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }
    if (name && pic && email && phone && regexEmail.test(email)) {
      const urlBase = `${hardBaseUrl}/association/update/${state.customer.association_id}`;
      const theToken = localStorage.getItem("token");
      const headers = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${theToken}`,
        },
      };
      const payload = {
        email: email,
        name: name,
        phone_number: phone,
        pic: pic,
        association_code: transmitterCode
      };
      axios
        .put(urlBase, payload, headers)
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              title: "Success.",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(result => {
              if (result.isConfirmed === true) {
                props.history.push("/admin/parameter/association");
              }
            });
          }
        })
        .catch(err => {
          Swal.fire({
            title: "Oops…",
            icon: "error",
            text: err?.response?.data?.errors[0]?.message,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    }
  };
  useEffect(() => {
    let tagArr = document.getElementsByTagName("input");
    for (let i = 0; i < tagArr.length; i++) {
      tagArr[i].autocomplete = "off";
    }
  }, []);

  return (
    <Page className={classes.root} title="Edit Association">
      <Backdrop className={classes.backdrop} open={loadingPage}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <HeaderTitle
          title="Edit Association"
          breadcrumbData={breadcrumbData}
          backButton
        />
        <Divider className={classes.divider} />
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ThemeProvider theme={fontInter}>
                <Typography variant="h5" className={classes.title}>
                  Association Profile
                </Typography>
                <Typography
                  variant="caption"
                  className={(classes.subTitle, classes.mt10)}
                >
                  Customize association profile and URL to Share
                </Typography>
              </ThemeProvider>
              <Box className={classes.divContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" gridGap={2}>
                      <Typography className={(classes.font14)}>
                        Association Name
                      </Typography>
                      <Typography className={(classes.required)}>
                        *
                      </Typography>
                    </Box>
                    <TextField
                      className={(classes.myTextField, classes.mt5)}
                      fullWidth
                      onChange={e => onChange(e)}
                      id={"name"}
                      name="name"
                      value={name}
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={(classes.font14, classes.mt15)}>
                      Transmitter Code
                    </Typography>
                    <TextField
                      className={(classes.myTextField, classes.mt5)}
                      fullWidth
                      onChange={e => onChange(e)}
                      id="transmitterCode"
                      name="transmitterCode"
                      value={transmitterCode}
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" gridGap={2} className={classes.mt15}>
                      <Typography className={classes.font14}>
                        PIC
                      </Typography>
                      <Typography className={classes.required}>
                        *
                      </Typography>
                    </Box>
                    <TextField
                      className={(classes.myTextField, classes.mt5)}
                      fullWidth
                      onChange={onChange}
                      id="pic"
                      name="pic"
                      value={pic}
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" gridGap={2} className={classes.mt15}>
                      <Typography className={classes.font14}>
                        Email
                      </Typography>
                      <Typography className={classes.required}>
                        *
                      </Typography>
                    </Box>
                    <TextField
                      className={(classes.myTextField, classes.mt5)}
                      fullWidth
                      onChange={onChange}
                      id={"email"}
                      name="email"
                      autoComplete="new-password"
                      value={email}
                      type="text"
                      variant="outlined"
                      InputProps={{
                        classes: { input: classes.inputFields },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" gridGap={2} className={classes.mt15}>
                      <Typography className={classes.font14}>
                        Phone Number
                      </Typography>
                      <Typography className={classes.required}>
                        *
                      </Typography>
                    </Box>
                    <TextField
                      className={(classes.myTextField, classes.mt5)}
                      fullWidth
                      onChange={onChange}
                      id="phone"
                      name="phone"
                      value={phone}
                      margin="normal"
                      type="tel"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <Box className={classes.mt10}>
            <PrimaryButton onClick={onSubmit} label="Save" />
          </Box>
        </form>
      </Container>
    </Page>
  );
};
export default EditAsosiasi;
