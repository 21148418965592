import { useEffect, useState } from "react";
import { Container, Divider } from "@material-ui/core";
import { Description, VisibilityOutlined } from "@material-ui/icons";
import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router";
import axios from "axios";
import { PrimaryIconButton } from "components";
import TrashVector from "assets/img/trashVector.svg";
import { getCookie } from "utils";
import {
  DateTimeDisplay,
  ModalError,
  Page,
  SkeletonComponent,
  StatusChip
} from "components";
import { HeaderTitle } from "layouts";
import { globalStyles } from "styles";
import { getErrors, wordCapitalize } from "utils";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { hardBaseUrl } from "../../../../services/urlConstant";

const typeChip = {
  published: "success",
  scheduled: "warning",
  draft: "warning",
  expired: "danger"
};

const DetailAnnouncementManagement = () => {
  const classes = globalStyles();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [pageDetail, setPageDetail] = useState({});
  const isEmail = pageDetail?.type === "email";
  const announcementFiles = pageDetail?.announcement_files;

  const getDetail = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(
        `${hardBaseUrl}/admin/annoucement/detail/${id}`,
        { headers }
      );
      setPageDetail(res?.data?.data);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <Page className={classes.root} title="Announcement Detail">
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title="Announcement Detail"
            breadcrumbData={breadcrumbData}
            backButton
            alignItems="center"
          />
          <Divider className={classes.divider} />
          <Grid container>
            <Grid item xs={12} sm={9} md={8}>
              <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" mt="16px">
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>Subject</Typography>
                    {pageDetail?.subject}
                  </Grid>
                  <Grid item xs={6}>
                    <Box justifyContent="right" display="flex" flexDirection="column" alignItems="flex-end">
                      <Box>
                        <StatusChip
                          label={wordCapitalize(pageDetail?.status)}
                          type={typeChip[pageDetail?.status]}
                        />
                      </Box>
                      <Typography>{pageDetail?.ID}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={600}>
                      {isEmail ? "Email Delivery Time" : "Start Date"}
                    </Typography>
                    <DateTimeDisplay
                      date={
                        isEmail
                          ? pageDetail?.email_delivery_time
                          : pageDetail?.web_start_date
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={600}>
                      {isEmail ? "Recipient" : "End Date"}{" "}
                    </Typography>
                    {isEmail ? (
                      wordCapitalize(pageDetail?.email_recipient)
                    ) : (
                      <DateTimeDisplay date={pageDetail?.web_end_date} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={600}>Uploaded File / Image</Typography>
                    <Grid
                      container
                      direction="column"
                      rowSpacing={1}
                      mt={1}
                    >
                      {announcementFiles?.length > 0 &&
                        announcementFiles?.map(
                          ({ file_name, file_path, size }, index) => (
                            <Grid item key={index} xs={12}>
                              <AttachmentFilesCard
                                fileName={file_name}
                                fileSize={size}
                                filePath={file_path}
                                tooltipPreview="View File"
                              />
                            </Grid>
                          )
                        )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={600}>Announcement Content</Typography>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: pageDetail?.content,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

const AttachmentFilesCard = ({
  fileName,
  fileSize,
  handleRemove,
  filePath,
  tooltipRemove,
  tooltipPreview,
}) => {
  const classes = globalStyles();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  return (
    <Box borderRadius="5px">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item color={userLogin?.publisher?.theme_color} xs={1}>
              <Description className={classes.colorGrey} />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1">{fileName}</Typography>
              {fileSize && <Typography variant="body2">{fileSize}</Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid container columnSpacing={1} alignItems="center" justifyContent="flex-end">
            {filePath && (
              <Grid item>
                <PrimaryIconButton
                  onClick={() => window.open(filePath)}
                  icon={<VisibilityOutlined />}
                  tooltipTitle={tooltipPreview}
                />
                <input type="file" hidden />
              </Grid>
            )}
            {handleRemove && (
              <Grid item>
                <PrimaryIconButton
                  onClick={handleRemove}
                  icon={<img alt="delete" src={TrashVector} />}
                  tooltipTitle={tooltipRemove}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};


const breadcrumbData = [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Management",
    link: "/admin/konfigurasi/announcement-management",
  },
  {
    label: "Announcement Detail",
    active: true,
  },
];
export default DetailAnnouncementManagement;
