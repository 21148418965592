import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalPreviewImage,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { getErrors } from "utils";

function Publisher() {
  const classes = globalStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const handlePreviewLogo = item => {
    setModalVisible(true);
    setPreviewImage(item);
  };

  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/publisher`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleActivateUser = async (id, restore) => {
    try {
      setLoadingPage(true);
      if (restore)
        await axios.put(
          `${hardBaseUrl}/publisher-recovery/${id}`,
          {},
          { headers }
        );
      else
        await axios.delete(`${hardBaseUrl}/publisher/${id}`, {
          headers,
        });

      ModalSuccess(
        `Successfully ${restore ? "Restore" : "Deactivate"} Publisher`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Publisher">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes?.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              my="16px"
            >
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <PrimaryButton
                  label="Add Publisher"
                  onClick={() => history.push("/admin/parameter/publisher/add")}
                  startIcon={<Add />}
                  size="large"
                />
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable({ classes, handlePreviewLogo })}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  deleteConfirmationTitle="Deactivate Publisher"
                  tooltipDelete="Deactivate Publisher"
                  deleteConfirmationContent={`Are you sure you want to deactivate ${item?.name} ?`}
                  handleDelete={
                    item?.is_active_flag
                      ? () => handleActivateUser(item?.publisher_id)
                      : null
                  }
                  restoreConfirmation
                  restoreConfirmationTitle="Restore Publisher"
                  tooltipRestore="Restore Publisher"
                  restoreConfirmationContent={`Are you sure you want to restore ${item?.name} ?`}
                  tooltipEdit="Update Publisher"
                  handleRestore={
                    !item?.is_active_flag
                      ? () => handleActivateUser(item?.publisher_id, true)
                      : null
                  }
                  handleEdit={() =>
                    history.push(
                      `/admin/parameter/publisher/${item.publisher_id}`
                    )
                  }
                />
              )}
            />
          </Fragment>
        )}
      </Container>
      {modalVisible && (
        <ModalPreviewImage
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          imageUrl={previewImage}
        />
      )}
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/publisher",
  },
  {
    label: "Publisher",
    active: true,
  },
];
const columnTable = ({ classes, handlePreviewLogo }) => [
  {
    name: "profile_image",
    title: "Logo",
    renderText: item => (
      <img
        className={classes?.cursorPointer}
        onClick={() => {
          handlePreviewLogo(item);
        }}
        src={item}
        alt=""
        width={100}
      />
    ),
  },
  {
    name: "name",
    title: "Publisher",
  },
  {
    name: "publisher_id",
    title: "ID Publisher",
  },
  {
    name: "subdomain",
    title: "URL",
    renderText: item => item || "-",
  },
  {
    name: "email",
    title: "Email",
    renderText: item => item || "-",
  },
  {
    name: "phone",
    title: "Phone",
    renderText: item => item || "-",
  },
];
export default Publisher;
