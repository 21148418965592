import React, { useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { makeStyles } from "@material-ui/styles";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Container,
  Divider,
  Grid,
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  Breadcrumbs,
  OutlinedInput,
} from "@material-ui/core";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  thePercent: {
    fontSize: "30px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  btnSubmit: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPengaturanPencipta: {
    backgroundColor: "black",
    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDialog: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "150px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  dropdown: {
    color: "black",
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      height: "1px",
    },
  },
  select: {
    "& .MuiSelect-root": {
      height: "4px",
    },
    "& .MuiSelect-select": {
      height: "4px",
    },
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  textStyle: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  card: {
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
  },
  mt5: {
    marginTop: "5px",
  },
  mtMin5: {
    marginTop: "-5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mtMin10: {
    marginTop: "-10px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mbMin5: {
    marginBottom: "-5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mbMin10: {
    marginBottom: "-10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mr5: {
    marginRight: "5px",
  },
  ml5: {
    marginLeft: "5px",
  },
  flex: {
    display: "flex",
  },
  between: {
    justifyContent: "space-between",
  },
  center: {
    justifyContent: "center",
  },
  color687083: {
    color: "#687083",
  },
  outline: {
    width: "100%",
    marginRight: "20px",
    height: "44px",
    border: "1px solid #D1D5DC",
    borderRadius: "6px",
  },
  pointer: {
    cursor: "pointer",
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    display: "flex",
    flexDirection: "column",
    background: "white",
    position: "absolute",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    zIndex: "1000",
  },
  containerForm: {
    width: "300px",
    minHeight: "100px",
    maxHeight: "300px",
    marginTop: "40px",
    borderTop: "1px solid #E4E7EB",
    overflow: "scroll",
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
  colorRed: {
    color: "red",
  },
}));

function TambahAgent(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const [agentName, setAgentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [npwp, setNpwp] = useState("");
  const [composerValue, setComposerValue] = useState([]);
  const [error, setError] = useState({
    agentName: false,
    email: false,
    phone: false,
  });

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "agentName") {
      if (value === "") {
        setError({
          ...error,
          agentName: true,
        });
        setAgentName(value);
      } else {
        setError({
          ...error,
          agentName: false,
        });
        setAgentName(value);
      }
    } else if (name === "email") {
      if (value === "") {
        setError({
          ...error,
          email: true,
        });
        setEmail(value);
      } else {
        setError({
          ...error,
          email: false,
        });
        setEmail(value);
      }
    } else if (name === "phone") {
      if (value === "") {
        setError({
          ...error,
          phone: true,
        });
        setPhone(value);
      } else {
        setError({
          ...error,
          phone: false,
        });
        setPhone(value);
      }
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "composerValue") {
      setComposerValue(value);
    }
  };

  const handleSaveData = e => {
    e.preventDefault();
    if (agentName === "" || email === "" || phone === "") {
      setError({
        agentName: agentName === "" ? true : false,
        email: email === "" ? true : false,
        phone: phone === "" ? true : false,
      });
    }

    if (agentName !== "" && email !== "" && phone !== "") {
      const url = `${hardBaseUrl}/publisher/agent`;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let newComposerValue = [];
      composerValue.map(item => {
        return newComposerValue.push(item.composer_id);
      });

      const data = {
        name: agentName,
        email: email,
        contact_number: phone,
        npwp: npwp,
      };

      axios
        .post(url, data, config)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been saved",
          });
          props.history.push("/admin/parameter/agent");
        }
        )
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.errors[0].message,
          });
        });
    }
  };

  return (
    <Page className={classes.root} title="Add Agent">
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>Add Agent</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbs}>Agent</Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Add Agent
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Box>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Agent Information
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>{"To add agent's data."}</p>
              </ThemeProvider>
            </Box>
          </Grid>

          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Box className={(classes.mt5, classes.mb5)}>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Agent Name
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Agent Name"
                            className={classes.textField}
                            variant="outlined"
                            fullWidth={true}
                            name="agentName"
                            onChange={handleChange}
                          />
                          <small className={classes.colorRed}>
                            {error.agentName ? "Agent Name is required" : ""}
                          </small>
                        </Box>
                      </Box>
                      <Box className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Email
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box className={(classes.mt10, classes.mb10)}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Email"
                          variant="outlined"
                          className={classes.textField}
                          fullWidth={true}
                          name="email"
                          onChange={handleChange}
                        />
                        <small className={classes.colorRed}>
                          {error.email ? "Email is required" : ""}
                        </small>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Box className={(classes.mt5, classes.mb5)}>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Telephone Number
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            fullWidth={true}
                            name="phone"
                            type="tel"
                            placeholder="Phone Number"
                            onChange={handleChange}
                            margin="dense"
                          />
                          <small className={classes.colorRed}>
                            {error.phone ? "Telephone Number is required" : ""}
                          </small>
                        </Box>

                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              NPWP
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="NPWP"
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth={true}
                            name="npwp"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <Box className={classes.gap4}>
              <PrimaryButton onClick={e => handleSaveData(e)} label="Save" />
              <PrimaryButton
                onClick={() => window.history.back()}
                label="Back"
              />
            </Box>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}

export default TambahAgent;
