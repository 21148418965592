import {
  Breadcrumbs,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl } from "services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  thePercent: {
    fontSize: "30px",
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  gap4: {
    display: "flex",
    gap: "4px",
    marginTop: "10px",
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPengaturanPencipta: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  btnDialog: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "150px",

    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mt5: {
    marginTop: "5px",
  },
  mtMin5: {
    marginTop: "-5px",
  },
  mt10: {
    marginTop: "10px",
  },
  mtMin10: {
    marginTop: "-10px",
  },
  mb5: {
    marginBottom: "5px",
  },
  mbMin5: {
    marginBottom: "-5px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mbMin10: {
    marginBottom: "-10px",
  },
  mb15: {
    marginBottom: "15px",
  },
  mr5: {
    marginRight: "5px",
  },
  ml5: {
    marginLeft: "5px",
  },
  headText: {
    fontSize: "12px",
    color: "#687083",
    fontWeight: "bold",
  },
  bodyTextBlack: {
    fontSize: "14px",
    fontWeight: "regular",
    color: "#111827",
    fontStyle: "normal",
  },
  dropdown: {
    color: "black",
  },
  conTextBlack: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  font24: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  fieldAgentFee: {
    marginTop: 0
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      height: "1px",
    },
  },
  select: {
    "& .MuiSelect-root": {
      height: "4px",
    },
    "& .MuiSelect-select": {
      height: "4px",
    },
  },
}));

function EditAgent(props) {
  const ref = useRef(null);
  const classes = useStyles();
  const { state } = useLocation();
  const [agentName, setAgentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [npwp, setNpwp] = useState("");
  const [feeAgent, setFeeAgent] = useState(0);
  const [composerValue, setComposerValue] = useState([]);
  const [composerList, setComposerList] = useState([]);
  const [error, setError] = useState({
    agentName: false,
    email: false,
    phone: false,
  });

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "agentName") {
      if (value === "") {
        setError({
          ...error,
          agentName: true,
        });
        setAgentName(value);
      } else {
        setError({
          ...error,
          agentName: false,
        });
        setAgentName(value);
      }
    } else if (name === "email") {
      if (value === "") {
        setError({
          ...error,
          email: true,
        });
        setEmail(value);
      } else {
        setError({
          ...error,
          email: false,
        });
        setEmail(value);
      }
    } else if (name === "phone") {
      if (value === "") {
        setError({
          ...error,
          phone: true,
        });
        setPhone(value);
      } else {
        setError({
          ...error,
          phone: false,
        });
        setPhone(value);
      }
    } else if (name === "npwp") {
      setNpwp(value);
    } else if (name === "agentFee") {
      setFeeAgent(value);
    } else if (name === "composerValue") {
      setComposerValue(value);
    }
  };

  const getDetail = () => {
    const url = `${hardBaseUrl}/publisher/agent/${state.customer.agent_id}`;
    const token = localStorage.getItem("token");
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, headers)
      .then(res => {
        setAgentName(res.data.data.name);
        setEmail(res.data.data.email);
        setPhone(res.data.data.contact_number);
        setNpwp(res.data.data.npwp);
        setFeeAgent(res.data.data.percentage_agent_fee);

        setComposerValue(res.data.data.composers);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  const getComposerList = () => {
    const url = `${hardBaseUrl}/publisher/composer`;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then(res => {
        setComposerList(res.data.data);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.errors[0].message,
        });
      });
  };

  useEffect(() => {
    getComposerList();
    getDetail();
  }, []);

  const handleSaveData = e => {
    e.preventDefault();
    if (agentName === "" || email === "" || phone === "") {
      setError({
        agentName: agentName === "" ? true : false,
        email: email === "" ? true : false,
        phone: phone === "" ? true : false,
      });
    }

    if (agentName !== "" && email !== "" && phone !== "") {
      const url = `${hardBaseUrl}/publisher/agent/${state.customer.agent_id}`;
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let newComposerValue = [];
      composerValue.map(item => {
        return newComposerValue.push(item.composer_id);
      });

      const data = {
        name: agentName,
        email: email,
        contact_number: phone,
        npwp: npwp,
      };

      axios
        .put(url, data, config)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data has been updated",
          });
          props.history.push("/admin/parameter/agent");
        })
        .catch(err => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.errors[0].message,
          });
        });
    }
  };

  return (
    <Page className={classes.root} title="Edit Agent">
      <Container maxWidth={false}>
        <form ref={ref}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography className={classes.font24}>Edit Agent</Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbs}>
                  Parameter
                </Typography>

                <Typography className={classes.breadCrumbs}>Agent</Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Edit Agent
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container>
            <Box>
              <ThemeProvider theme={theme}>
                <Typography
                  component="h1"
                  variant="h3"
                  className={classes.subTitle}
                >
                  Agent Information
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <p className={classes.textStyle}>{"To edit agent's data."}</p>
              </ThemeProvider>
            </Box>
          </Grid>

          <Grid container>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Box className={(classes.mt5, classes.mb5)}>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Agent Name
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Agent Name"
                            value={agentName}
                            className={classes.textField}
                            variant="outlined"
                            fullWidth={true}
                            name="agentName"
                            onChange={handleChange}
                          />
                          <small className={classes.colorRed}>
                            {error.agentName ? "Agent Name is required" : ""}
                          </small>
                        </Box>
                      </Box>
                      <Box className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Email
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box className={(classes.mt10, classes.mb10)}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Email"
                          value={email}
                          variant="outlined"
                          className={classes.textField}
                          fullWidth={true}
                          name="email"
                          onChange={handleChange}
                        />
                        <small className={classes.colorRed}>
                          {error.email ? "Email is required" : ""}
                        </small>
                      </Box>

                      <Box className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Agent Fee
                          </Typography>
                        </ThemeProvider>
                      </Box>
                      <Box className={(classes.mt5, classes.mb10)}>
                        <TextField
                          id="outlined-basic"
                          name="agentFee"
                          variant="outlined"
                          className={classes.fieldAgentFee}
                          placeholder="Share Percentage"
                          fullWidth={true}
                          value={feeAgent}
                          margin="dense"
                          type="number"
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {"%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Box className={(classes.mt5, classes.mb5)}>
                        <Box className={classes.mb10}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Telephone Number
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={classes.mb10}>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            fullWidth={true}
                            name="phone"
                            type="tel"
                            value={phone}
                            placeholder="Phone Number"
                            onChange={handleChange}
                            margin="dense"
                          />
                          <small className={classes.colorRed}>
                            {error.phone ? "Telephone Number is required" : ""}
                          </small>
                        </Box>

                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              NPWP
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={(classes.mt10, classes.mb10)}>
                          <TextField
                            id="outlined-basic"
                            placeholder="NPWP"
                            value={npwp}
                            onChange={handleChange}
                            variant="outlined"
                            className={classes.textField}
                            fullWidth={true}
                            name="npwp"
                          />
                        </Box>

                        <Box className={(classes.mt10, classes.mb10)}>
                          <ThemeProvider theme={theme}>
                            <Typography className={classes.label}>
                              Composer
                            </Typography>
                          </ThemeProvider>
                        </Box>
                        <Box className={classes.mt10}>
                          <Autocomplete
                            id="combo-box-demo"
                            multiple={true}
                            options={composerList}
                            ListboxProps={{
                              style: { maxHeight: 200, overflow: "auto" },
                            }}
                            getOptionLabel={option => option.sure_name}
                            value={composerValue}
                            onChange={(_, newValue) => {
                              setComposerValue(newValue);
                            }}
                            disablePortal={true}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Choose Composer"
                                variant="outlined"
                                className={classes.textField}
                                name="composer"
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>

          <Grid container>
            <Box className={classes.gap4}>
              <PrimaryButton onClick={e => handleSaveData(e)} label="Save" />
              <PrimaryButton
                onClick={() => window.history.back()}
                label="Back"
              />
            </Box>
          </Grid>
        </form>
      </Container>
      { }
    </Page>
  );
}

export default EditAgent;
