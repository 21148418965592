import { Container, Divider } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  CurrencyNumberInput,
  DatePicker,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import { hardBaseUrl } from "services/urlConstant";
import { globalStyles } from "styles";
import { formatPayloadDate, getCookie, getErrors } from "utils";

const AddLoan = () => {
  const classes = globalStyles();
  const history = useHistory();
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [submitting, setSubmitting] = useState(false);
  const [payload, setPayload] = useState({
    date: moment(),
    value: 0,
    tenor: 0,
    note: "",
  });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    const modifiedPayload = {
      ...payload,
      date: formatPayloadDate(payload?.date),
    };
    try {
      setSubmitting(true);
      await axios.post(
        `${hardBaseUrl}/composer/transaction/loan`,
        modifiedPayload,
        { headers }
      );
      ModalSuccess("Load has been added").then(() => history.goBack());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <HeaderTitle
          title="Loan Transaction"
          breadcrumbData={breadcrumbData}
          backButton
        />
        <Divider className={classes.divider} />
        <SectionLabel
          title="Create Transaction"
          subTitle="Create your loan transaction here."
        />
        <Box border="1px solid #ebebeb" borderRadius="5px" p="24px" my="16px">
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12} md={6}>
              <FormLabel label="Date" />
              <DatePicker
                value={payload?.date}
                onChange={date => handleChangePayload(date, "date")}
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel label="Publisher" />
              <TextInput value={userLogin?.publisher?.name} disabled />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel label="Tenor" />
              <CurrencyNumberInput
                value={payload?.tenor}
                endAdornment="Month"
                onChange={data => handleChangePayload(data, "tenor")}
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel label="Loan Value" />
              <CurrencyNumberInput
                value={payload?.value}
                startAdornment="Rp "
                onChange={data => handleChangePayload(data, "value")}
                disabled={submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel label="Notes" />
              <TextInput
                value={payload?.note}
                placeholder="Notes"
                onChange={e => handleChangePayload(e?.target?.value, "note")}
                multiline
                rows={4}
                disabled={submitting}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="right">
          <PrimaryButton
            label={submitting ? "Submitting" : "Add"}
            onClick={handleSubmit}
            disabled={submitting}
            loading={submitting}
          />
        </Grid>
      </Container>
    </Page>
  );
};

const breadcrumbData = [
  {
    label: "Transaction",
    link: "/pencipta/loan/",
  },
  {
    label: "Loan",
    link: "/pencipta/loan/",
  },
  {
    label: "Create Transaction",
    active: true,
  },
];

export default AddLoan;
