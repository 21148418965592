import { makeStyles } from "@material-ui/styles";
import { getCookie } from "utils";
const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);

export const globalStyles = makeStyles({
  root: {
    padding: "24px 0",
  },
  divider: {
    margin: "16px 0 !important",
    borderTop: "1px solid #e1e1e1 !important",
  },
  flatDivider: {
    borderTop: "1px solid #e1e1e1 !important",
  },
  image: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
  },
  colorGrey: {
    color: "#9AA2B1"
  },
  timer: {
    display: "flex",
    alignItems: "center",
  },
  buttonAction: {
    backgroundColor: "#111827",
    color: "white",
    width: "28px",
    height: "28px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "8px",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
      color: "white",
    },
  },
  chartCard: {
    border: "1px solid #ebebeb",
    borderRadius: "5px",
    padding: "50px 20px",
  },
  filterArea: {
    backgroundColor: "#F9FAFB",
    padding: "20px 10px",
  },
  groupButton: {
    textTransform: "none",
    border: "1px solid #D1D5DC",
    color: "#111827",
    height: 42,
    lineHeight: "20px",
    fontSize: "0.95em",
    fontWeight: "500",
    fontFamily: "Inter",
    "&:hover": {
      color: "#111827",
    },
  },
  groupButtonSelected: {
    backgroundColor: "#E4E7EB",
    color: "#111827",
    textTransform: "none",
    border: "1px solid #D1D5DC",
    height: 42,
    lineHeight: "20px",
    fontSize: "0.95em",
    fontWeight: "500",
    fontFamily: "Inter",
    "&:hover": {
      backgroundColor: "#D1D5DC",
      color: "#111827",
    },
  },
  groupButtonScrollContainer: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    "-webkit-overflow-scrolling": "touch",
    scrollbarWidth: "thin",
    paddingBottom: "10px",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  groupButtonRootContainer: {
    padding: "10px 0px",
    overflow: "hidden",
  },
  highlightText: {
    fontWeight: "bold",
  },
  balanceTitle: {
    fontFamily: "Inter",
    color: "#9AA2B1",
    fontSize: 14,
  },
  balanceValue: {
    fontFamily: "Inter",
    color: "white",
    fontSize: 24,
    fontWeight: 600,
  },
  publisherProfileImage: {
    objectFit: "contain",
  },
  uploadIcon: {
    fontSize: 50,
    color: "grey",
  },
  dragFilesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
    borderRadius: "8px",
    borderStyle: "dashed",
    transition: "0.24s ease-in-out",
    cursor: "pointer",
  },
  dragActive: {
    boxShadow: `0px 0px 8px ${userLogin?.publisher?.theme_color || "#2196f3"}`,
  },
  rightAlign: {
    display: "flex",
    justifyContent: "flex-end",
  },
  required: { color: "red" },
  bankAccountImage: {
    width: "48px",
    height: "21px",
    objectFit: "contain",
  },
  modalUploadResultIcon: {
    fontSize: 100,
    color: "#4caf50",
  },
  filePreviewContainer: {
    width: "100%",
    height: "100%",
  },
  passwordVisibiltyIcon: {
    width: "16px",
    cursor: "pointer",
  },
  loginFieldIcon: {
    width: "16px",
  },
  "&.MuiTableCell-alignRight": {
    display: "flex",
    textAlign: "right",
    flexDirection: "row-reverse",
  },
  publisherLogo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  chipIndicator: {
    height: 8,
    width: 8,
  },
  cursorPointer: {
    cursor: "pointer",
  },
});
