import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  ClickAwayListener,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputPassword from "../../../../components/atoms/Input/InputPassword";
import { Page, PrimaryButton } from "components";
import { hardBaseUrl, hardToken } from "../../../../services/urlConstant";
import { useHistory } from "react-router";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TambahUser(props) {
  const history = useHistory();
  const [listRole, setListRole] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("success");
  const [message, setMessage] = useState("This is a message!");
  const [getColor, setGetColor] = useState("");
  const publisherId = useSelector(state => state.publisherId);
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleSearch, setRoleSearch] = useState("");
  const roleType = localStorage.getItem("role");
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        let baseOfUrl = hardBaseUrl;
        const url =
          baseOfUrl +
          `/role?type=${roleType === "admin" ? "admin" : "publisher"}`;
        const options = {
          headers: {
            Authorization: "Bearer " + hardToken,
          },
        };
        const response = await axios.get(url, options);
        setListRole(response.data.data);
      } catch (error) {}
    };
    const getMainDsp = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${hardBaseUrl}/me`;
        const res = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setGetColor(res.data.data.publisher.theme_color);
      } catch (e) {}
    };
    getMainDsp();
    fetchRoles();
  }, []);

  const handleChangeName = item => {
    setName(item.target.value);
  };
  const handleChangeEmail = item => {
    setEmail(item.target.value);
  };
  const handleChangePhone = item => {
    setPhone(item.target.value);
  };
  const handleChangePassword = item => {
    setPassword(item.target.value);
  };
  const handleButtonSimpan = async () => {
    if (name === "") {
      setTypeSnackbar("error");
      setMessage("Nama Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (email === "") {
      setTypeSnackbar("error");
      setMessage("Email Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (phone === "") {
      setTypeSnackbar("error");
      setMessage("Phone Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (password === "") {
      setTypeSnackbar("error");
      setMessage("Password Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    if (selectedRoles.length === 0) {
      setTypeSnackbar("error");
      setMessage("Role Tidak Boleh Kosong.");
      setOpenSnack(true);
    }
    const selectedRolesId = selectedRoles?.map(obj => obj?.role_id);
    if (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      password !== "" &&
      selectedRoles?.length > 0
    ) {
      let payload = {
        name: name,
        email: email,
        phone: phone,

        password: password,
        role_ids: selectedRolesId,

        publisher_id: publisherId.publisherId,
      };

      let baseOfUrl = hardBaseUrl;
      let urlUse = baseOfUrl + "/publisher/user";
      axios
        .post(urlUse, payload, {
          headers: {
            Authorization: "Bearer " + hardToken,
          },
        })
        .then(() => {
          setTypeSnackbar("success");
          Swal.fire({
            title: "Success",
            text: "Data Has Been Added",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(result => {
            if (result.isConfirmed) {
              setOpenSnack(true);
              props.history.push("/admin/parameter/user");
            }
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Error",
            text: err.response.data.errors[0].message,
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const clickMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleClickAway = () => {
    setOpenMenu(false);
  };

  const handleChangeSearchRole = event => {
    setRoleSearch(event?.target?.value);
  };
  const handleChangeCheckBox = (event, role, index) => {
    const updatedRoles = [...listRole];
    updatedRoles[index].checked = event.target.checked;
    setListRole(updatedRoles);

    if (event.target.checked) {
      if (
        !selectedRoles.find(
          selectedRole => selectedRole.role_id === role.role_id
        )
      ) {
        setSelectedRoles(prevSelectedRoles => [...prevSelectedRoles, role]);
      }
    } else {
      setSelectedRoles(prevSelectedRoles =>
        prevSelectedRoles.filter(
          selectedRole => selectedRole.role_id !== role.role_id
        )
      );
    }
  };

  const filteredRoles = listRole?.filter(role =>
    role?.name?.toLowerCase().includes(roleSearch?.toLowerCase())
  );
  const formatRoleNames = roles => {
    const maxCharCount = 40;
    if (roles?.length === 0) {
      return "Choose Role";
    } else {
      const roleString = roles
        .map(selectedRole => selectedRole.name)
        .join(", ");
      if (roleString.length <= maxCharCount) {
        return roleString;
      } else {
        return roleString.substring(0, maxCharCount) + "...";
      }
    }
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    btnSubmit: {
      backgroundColor: "black",
      marginLeft: "10px",
      color: "white",
      padding: "10px 28px",
      width: "106px",
      height: "40px",
      borderRadius: "6px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    btnPreview: {
      backgroundColor: "black",
      marginLeft: "10px",
      color: "white",
      padding: "10px 28px",
      width: "106px",
      height: "40px",
      borderRadius: "6px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    mypadding: {
      "& .MuiSelect-outlined": {
        paddingTop: "10.5px",
        paddingBottom: "10.5px",
      },
    },
    label: {
      color: "#364052",
      fontSize: "14px",
      fontWeight: 500,
    },
    subTitle: {
      fontSize: "18px",
      fontWeight: 700,
      color: " #111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    textStyle: {
      marginTop: "5px",
      color: "#8f8f8f",
      fontSize: "14px",
    },
    card: {
      border: "1px solid #9AA2B1",
      borderRadius: "6px",
      boxShadow: "none",
    },
    mt5: {
      marginTop: "5px",
    },
    mtMin5: {
      marginTop: "-5px",
    },
    mt10: {
      marginTop: "10px",
    },
    mtMin10: {
      marginTop: "-10px",
    },
    mb5: {
      marginBottom: "5px",
    },
    mbMin5: {
      marginBottom: "-5px",
    },
    mb10: {
      marginBottom: "10px",
    },
    mbMin10: {
      marginBottom: "-10px",
    },
    mb15: {
      marginBottom: "15px",
    },
    mr5: {
      marginRight: "5px",
    },
    ml5: {
      marginLeft: "5px",
    },
    flex: {
      display: "flex",
    },
    between: {
      justifyContent: "space-between",
    },
    center: {
      justifyContent: "center",
    },
    color687083: {
      color: "#687083",
    },
    outline: {
      width: "100%",
      marginRight: "20px",
      height: "44px",
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
    pointer: {
      cursor: "pointer",
    },
    paper: {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      display: "flex",
      flexDirection: "column",
      background: "white",
      position: "absolute",
      "& > *": {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(36),
      },
      zIndex: "1000",
    },
    containerForm: {
      width: "300px",
      minHeight: "100px",
      maxHeight: "300px",
      marginTop: "40px",
      borderTop: "1px solid #E4E7EB",
      overflow: "scroll",
    },
    gap4: {
      display: "flex",
      gap: "4px",
      marginTop: "10px",
    },
    container: {
      width: "100% !important",
      height: "358px",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  }));
  const classes = useStyles();
  return (
    <Page className={classes.root} title="Add User">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
            {message}
          </Alert>
        </Snackbar>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography className={classes.font24}>Add User</Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>
              <Typography className={classes.breadCrumbs}>Parameter</Typography>
              <Typography className={classes.breadCrumbs}>User</Typography>
              <Typography className={classes.breadCrumbsActive}>
                Add User
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <div>
            <ThemeProvider theme={theme}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                User Information
              </Typography>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <p className={classes.textStyle}>{"Add User's Data"}</p>
            </ThemeProvider>
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={8} md={8} sm={8} lg={8} xl={8}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div className={(classes.mb5, classes.mt5)}>
                      <div className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Full Name
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div className={(classes.mtMin10, classes.mb10)}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Full Name"
                          fullWidth={true}
                          margin="dense"
                          value={name}
                          onChange={event => handleChangeName(event)}
                        />
                      </div>
                      <div className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Email
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div className={(classes.mtMin10, classes.mb10)}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          placeholder="Email"
                          autoComplete="email"
                          value={email}
                          type="email"
                          onChange={event => handleChangeEmail(event)}
                        />
                      </div>
                      <div className={(classes.mt10, classes.mb10)}>
                        <ThemeProvider theme={theme}>
                          <Typography className={classes.label}>
                            Password
                          </Typography>
                        </ThemeProvider>
                      </div>
                      <div className={(classes.mtMin10, classes.mbMin10)}>
                        <InputPassword
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          autoComplete="new-password"
                          value={password}
                          placeholder="Password"
                          type="password"
                          onChange={event => handleChangePassword(event)}
                        />
                        <small className={classes.color687083}>
                          Minimum 8 Characters
                        </small>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <div className={(classes.mt10, classes.mb15)}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Role Name
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div className={(classes.mt10, classes.mb10)}>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                          <OutlinedInput
                            id="outlined-adornment-amount"
                            size="small"
                            onClick={clickMenu}
                            className={classes.outline}
                            startAdornment={
                              <InputAdornment position="start">
                                {formatRoleNames(selectedRoles)}
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <ArrowDropDown
                                  onClick={clickMenu}
                                  className={classes.pointer}
                                />
                              </InputAdornment>
                            }
                            fullWidth={false}
                            readOnly={true}
                          />
                          {openMenu && (
                            <Paper variant="outlined" className={classes.paper}>
                              <Container className={classes.container}>
                                <FormControl
                                  className={
                                    (classes.flex, classes.center, classes.mt10)
                                  }
                                >
                                  <TextField
                                    autoFocus={true}
                                    id="fliterText"
                                    name="filterText"
                                    className={classes.inputFields}
                                    variant="outlined"
                                    placeholder="Search"
                                    onChange={handleChangeSearchRole}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            alt="Logo"
                                            src={
                                              require("assets/image-public/images/search.svg")
                                                .default
                                            }
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                                <FormControl>
                                  {filteredRoles?.map((item, index) => {
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        control={
                                          <Checkbox
                                            className={classes.myCheckBox}
                                            id={`${item?.dsp_id}`}
                                            key={index}
                                            checked={item?.checked}
                                            name={item?.name}
                                            value={item?.dsp_id}
                                            onChange={e =>
                                              handleChangeCheckBox(
                                                e,
                                                item,
                                                index
                                              )
                                            }
                                          />
                                        }
                                        label={item?.name}
                                      />
                                    );
                                  })}
                                </FormControl>
                              </Container>
                            </Paper>
                          )}
                        </div>
                      </ClickAwayListener>
                    </div>
                    <div className={(classes.mt10, classes.mb15)}>
                      <ThemeProvider theme={theme}>
                        <Typography className={classes.label}>
                          Phone Number
                        </Typography>
                      </ThemeProvider>
                    </div>
                    <div className={(classes.mtMin10, classes.mb10)}>
                      <OutlinedInput
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth={true}
                        type="tel"
                        margin="dense"
                        placeholder="Phone Number"
                        value={phone}
                        className={classes.tlpnField}
                        onChange={event => handleChangePhone(event)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4} xl={4}></Grid>
        </Grid>
        <Grid container>
          <div className={(classes.flex, classes.mt10, classes.gap4)}>
            <PrimaryButton onClick={handleButtonSimpan} label="Save" />
            <PrimaryButton
              onClick={() => history.push("/admin/parameter/user")}
              label="Back"
            />
          </div>
        </Grid>
      </Container>
    </Page>
  );
}

export default TambahUser;
