import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  FormLabel,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import { useEffect, useRef, useState } from "react";

const ModalFormBank = ({
  open,
  onClose,
  preload,
  handleSubmit,
  loadingButton,
}) => {
  const uploadInputRef = useRef(null);
  const [payload, setPayload] = useState({
    name: "",
    code: "",
  });
  const [bankLogo, setBankLogo] = useState({ image_url: null, image: null });
  const buttonDisabled =
    !payload?.name ||
    !payload?.code ||
    !bankLogo?.image_url ||
    JSON.stringify({ ...payload, profile_image: bankLogo.image_url }) ===
      JSON.stringify({ ...preload, profile_image: preload?.profile_image });

  const handleChangePayload = (value, key) => {
    setPayload(currentState => ({
      ...currentState,
      [key]: value,
    }));
  };
  const handleChangeImage = e => {
    const selectedFiles = e.target.files[0];
    if (selectedFiles) {
      setBankLogo({
        image_url: URL.createObjectURL(selectedFiles),
        image: selectedFiles,
      });
    } else {
      setBankLogo({
        image_url: null,
        image: null,
      });
    }
  };
  const getPreload = () => {
    setPayload(preload);
    setBankLogo(prev => ({
      ...prev,
      image_url: preload?.profile_image,
    }));
  };

  const onSubmit = () => {
    handleSubmit({ payload, bankLogo });
  };

  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog fullWidth open={open} onClose={!loadingButton && onClose}>
      <DialogTitle>{`${preload ? "Edit" : "Add"} Bank`}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <FormLabel label="Bank Name" required />
            <TextInput
              placeholder="Bank Name"
              value={payload?.name}
              onChange={e => handleChangePayload(e?.target?.value, "name")}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Bank Code" required />
            <TextInput
              placeholder="Bank Code"
              value={payload?.code}
              onChange={e => handleChangePayload(e?.target?.value, "code")}
              disabled={loadingButton}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Bank Logo" required />
            <Box border="1px solid #ebebeb" borderRadius="5px" p="16px">
              <Grid container columnSpacing={2}>
                <Grid item>
                  <Box display="none">
                    <input
                      ref={uploadInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleChangeImage}
                    />
                  </Box>
                  <PrimaryButton
                    label="Upload"
                    onClick={() =>
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                  />
                </Grid>
                <Grid item xs display="flex" justifyContent="center">
                  {bankLogo.image_url ? (
                    <img alt="logo" src={bankLogo.image_url} width={400} />
                  ) : (
                    <Typography>Upload Logo</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label={
            loadingButton
              ? preload
                ? "Updating"
                : "Submitting"
              : preload
              ? "Update"
              : "Add"
          }
          loading={loadingButton}
          disabled={buttonDisabled || loadingButton}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalFormBank;
