import { Container, Divider } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Grid } from "@mui/material";
import axios from "axios";
import {
  ButtonGroupTop,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SearchTextInput,
  SelectInput,
  SkeletonComponent,
  TableAction,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import { Fragment, useCallback, useEffect, useState } from "react";
import { globalStyles } from "styles";
import Swal from "sweetalert2";
import { emptyText, getErrors } from "utils";
import { hardBaseUrl } from "services/urlConstant";
import DialogEditUserPencipta from "./DialogEditUserPencipta";
import DialogTambahUserPencipta from "./DialogTambahUserPencipta";

function UserPencipta() {
  const classes = globalStyles();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [optionRole, setOptionRole] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
    role_id: "",
    type: "composer",
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };

  const getOptionRole = async () => {
    try {
      const res = await axios.get(`${hardBaseUrl}/role?type=composer`, {
        headers,
      });
      setOptionRole(res?.data?.data);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    }
  };
  const getDataTable = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/user`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      setDataTable(data || []);
      const pageCount = getTotalPage(meta?.total, queryParams?.size);
      setTableTotalPage(pageCount || 1);
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleActivateUser = async (id, restore) => {
    try {
      setLoadingPage(true);
      if (restore)
        await axios.put(`${hardBaseUrl}/user-recovery/${id}`, {}, { headers });
      else
        await axios.delete(`${hardBaseUrl}/user/${id}`, {
          headers,
        });

      ModalSuccess(
        `Successfully ${restore ? "Restore" : "Deactivate"} User Composer`
      ).then(() => getDataTable());
    } catch (error) {
      ModalError(error?.response ? getErrors(error?.response) : error);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getOptionRole();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
    }
  }, [queryParams, debounceDataTable]);

  let theToken = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confrimPassword, setConfrimPassword] = useState("");
  const [id, setId] = useState();
  const [publisherList, setPublisherList] = useState([]);

  const [pencipta, setPencipta] = useState(null);
  const [penciptaList, setPenciptaList] = useState([]);

  const handleOpen = () => {
    setOpen(true);
    setName("");
    setRole(null);
    setPublisher(null);
    setPhone("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setName("");
    setRole(null);
    setPublisher(null);
    setPhone("");
    setEmail("");
    setPencipta(null);
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "name") {
      setName(value);
    } else if (name === "role") {
      setRole(value);
    } else if (name === "pencipta") {
      setPencipta(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "konfirmasiPassword") {
      setConfrimPassword(value);
    }
  };

  const handleChangeSelectRole = e => {
    setRole(e.target.value);
  };

  const handleChangeSelectPublisher = e => {
    setPublisher(e.target.value);
  };

  const handleChangeSelectPencipta = e => {
    setPencipta(e.target.value);
  };
  const notifError = (text, confrimed = () => setOpen(true)) =>
    Swal.fire({
      title: "Oops…",
      icon: "error",
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(result => {
      if (result.isConfirmed) {
        confrimed();
      }
    });
  const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  useEffect(() => {
    if (!open) {
      setPassword("");
    }
  }, [open]);
  const onSubmitAdd = e => {
    e.preventDefault();
    setOpen(false);

    if (name === "") {
      return notifError("Username can't be empty");
    }
    if (pencipta === null) {
      return notifError("Composer can't be empty");
    }
    if (role === null) {
      return notifError("Role can't be empty");
    }
    if (password === "") {
      return notifError("Password can't be empty");
    }
    if (confrimPassword === "") {
      return notifError("Confirm password can't be empty");
    }
    if (email === "") {
      return notifError("Email address can't be empty");
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com"
      );
    }
    if (password !== confrimPassword) {
      return notifError("The confirmation password does not match");
    }
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      role !== null &&
      confrimPassword &&
      password === confrimPassword &&
      pencipta !== null
    ) {
      const url = `${hardBaseUrl}/user`;
      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        composer_id: pencipta,
        password: password,
        publisher_id: null,
      };
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 200
          ) {
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data added successfully",
            });

            setOpen(false);
          }
          getDataTable();
        })
        .catch(err => {
          if (err.response.data.errors.length) {
            notifError(err.response.data.errors[0]?.message);
          }
        });
    }
  };

  const handleEditMode = id => {
    setId(id);
    const url = `${hardBaseUrl}/user/${id}`;
    let theToken = localStorage.getItem("token");
    handleOpenEdit();
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setName(res.data.data.name);
        setPencipta(res.data.data.composer_id);
        setRole(res.data.data.role_id);
        setPhone(res.data.data.phone);
        setEmail(res.data.data.email);
      })
      .catch(error => {
        new Error(error);
      });
  };

  const handleUpdate = e => {
    e.preventDefault();

    setOpenEdit(false);

    if (name === "") {
      return notifError("Username can't be empty", () => setOpenEdit(true));
    }
    if (pencipta === null) {
      return notifError("Composer can't be empty", () => setOpenEdit(true));
    }
    if (role === null) {
      return notifError("Role can't be empty", () => setOpenEdit(true));
    }
    if (email === "") {
      return notifError("Email address can't be empty", () =>
        setOpenEdit(true)
      );
    }
    if (!regexEmail.test(email)) {
      return notifError(
        "Please enter your email address in format: yourname@example.com",
        () => setOpenEdit(true)
      );
    }

    if (name !== "" && email !== "" && role !== "" && publisher !== "") {
      const url = `${hardBaseUrl}/user/${id}`;

      const data = {
        name: name,
        email: email,
        phone: phone,
        role_id: role,
        publisher_id: publisher,
        composer_id: pencipta,
      };

      axios
        .put(url, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${theToken}`,
          },
        })
        .then(res => {
          if (
            res.data.message === "success" &&
            res.data.meta.http_status === 202
          ) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Data changed successfully",
            }).then(result => {
              if (result.isConfirmed === true) {
                getDataTable();
              }
            });
          }
        })
        .catch(err => {
          if (err.response.data) {
            notifError(err?.response?.data?.errors[0]?.message, () =>
              setOpenEdit(true)
            );
          }
        });
    }
  };

  const getPenciptaList = () => {
    const url = `${hardBaseUrl}/composer-option`;
    let theToken = localStorage.getItem("token");
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setPenciptaList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };

  const getPublisherList = () => {
    const url = `${hardBaseUrl}/publisher`;
    let theToken = localStorage.getItem("token");

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${theToken}`,
        },
      })
      .then(res => {
        setPublisherList(res.data.data);
      })
      .catch(err => {
        new Error(err);
      });
  };

  useEffect(() => {
    getPublisherList();
    getPenciptaList();
  }, []);

  return (
    <Page className={classes.root} title="User Composer">
      <Container maxWidth={false}>
        <HeaderTitle title="Parameter" breadcrumbData={breadcrumbData} />
        <ButtonGroupTop />
        <Divider className={classes.flatDivider} />
        {loadingPage ? (
          <SkeletonComponent variant="wave" />
        ) : (
          <Fragment>
            <Grid container justifyContent="space-between" my="16px">
              <Grid item>
                <SearchTextInput
                  placeholder="Search"
                  value={queryParams?.search}
                  onChange={e =>
                    handleChangeQueryParams(e?.target?.value, "search")
                  }
                />
              </Grid>
              <Grid item>
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item>
                    <SelectInput
                      label="Role"
                      options={optionRole}
                      optionKey="role_id"
                      optionLabel="name"
                      value={queryParams?.role_id || ""}
                      onChange={e =>
                        handleChangeQueryParams(e?.target?.value, "role_id")
                      }
                      placeholder="All Role"
                      width={200}
                    />
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      label="Add User Composer"
                      onClick={handleOpen}
                      startIcon={<Add />}
                      size="large"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={item => (
                <TableAction
                  deleteConfirmation
                  deleteConfirmationTitle="Deactivate User Composer"
                  deleteConfirmationContent={`Are you sure you want to deactivate ${item?.name} ?`}
                  handleDelete={
                    item?.is_active_flag
                      ? () => handleActivateUser(item?.user_id)
                      : null
                  }
                  restoreConfirmation
                  restoreConfirmationTitle="Restore User Composer"
                  restoreConfirmationContent={`Are you sure you want to restore ${item?.name} ?`}
                  handleRestore={
                    !item?.is_active_flag
                      ? () => handleActivateUser(item?.user_id, true)
                      : null
                  }
                  handleEdit={() => handleEditMode(item?.user_id)}
                />
              )}
            />
          </Fragment>
        )}
      </Container>
      <DialogTambahUserPencipta
        open={open}
        onClose={handleClose}
        role={role}
        composer={pencipta}
        handleChangeSelectRole={handleChangeSelectRole}
        handleChange={handleChange}
        onSubmit={onSubmitAdd}
        publisher={publisher}
        handleChangeSelectPencipta={handleChangeSelectPencipta}
        penciptaItems={penciptaList}
        roleItems={optionRole}
        name={name}
        phone={phone}
        email={email}
      />
      <DialogEditUserPencipta
        open={openEdit}
        onClose={handleCloseEdit}
        role={role}
        handleChangeSelectRole={handleChangeSelectRole}
        handleChange={handleChange}
        publisher={publisher}
        handleChangeSelectPublisher={handleChangeSelectPublisher}
        name={name}
        phone={phone}
        onSubmit={handleUpdate}
        email={email}
        publisherItems={publisherList}
        roleItems={optionRole}
        composer={pencipta}
        penciptaItems={penciptaList}
        handleChangeSelectPencipta={handleChangeSelectPencipta}
      />
    </Page>
  );
}

const breadcrumbData = [
  {
    label: "Parameter",
    link: "/admin/parameter/user-pencipta",
  },
  {
    label: "User Composer",
    active: true,
  },
];
const columnTable = [
  {
    name: "name",
    title: "User Name",
    renderText: item => emptyText(item),
  },
  {
    name: "role",
    title: "Role",
    renderText: item => emptyText(item?.name),
  },
  {
    name: "phone",
    title: "Phone Number",
    renderText: item => emptyText(item),
  },
  {
    name: "email",
    title: "Email",
    renderText: item => emptyText(item),
  },
];

export default UserPencipta;
