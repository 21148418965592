import { CheckboxInput } from "components";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  styled,
  TextField,
} from "@mui/material";

const AutoCompleteWithSearch = ({
  loading,
  size,
  label,
  value,
  options,
  optionLabel,
  onChange,
  inputValue,
  onInputChange,
  width,
  height,
  multiple,
  hasMore,
  setQueryParams,
  placeholder,
  disabledKey,
  dropdownHeight,
  ...rest
}) => {
  const handleScroll = event => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !loading && hasMore)
      setQueryParams(prev => ({ ...prev, page: prev.page + 1 }));
  };
  const handleOnChange = (event, newValue) => {
    const updatedValue = multiple
      ? [
          ...value.filter(option => option?.[disabledKey] === false),
          ...newValue.filter(option => option?.[disabledKey] !== false),
        ]
      : newValue;

    onChange(updatedValue);
  };

  return (
    <AutoCompleteComponent
      size={size || "small"}
      multiple={multiple}
      loading={loading}
      options={options}
      value={value}
      onChange={handleOnChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            size="small"
            key={`${index}`}
            label={option?.[optionLabel]}
            {...getTagProps({ index })}
            disabled={disabledKey && !option?.[disabledKey]}
          />
        ))
      }
      getOptionLabel={option => option?.[optionLabel] || ""}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        onInputChange(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple ? (
            <CheckboxInput label={option?.[optionLabel]} checked={selected} />
          ) : (
            option?.[optionLabel]
          )}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
        style: {
          maxHeight: dropdownHeight || 300,
          overflow: "auto",
        },
      }}
      width={width}
      height={height}
      {...rest}
    />
  );
};

const AutoCompleteComponent = styled(Autocomplete)(({ width, height }) => ({
  width: width || 300,
  height: height,
}));

export default AutoCompleteWithSearch;
