import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { ModalError, ModalSuccess } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import FormInvoice from "./Components/FormInvoice";
import { getErrors } from "utils";

function EditInvoice() {
  const history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [loadingPage, setLoadingPage] = useState(false);
  const [preload, setPreload] = useState({});

  const getDetailInvoice = async () => {
    const url = `${hardBaseUrl}/publisher/transaction/detail/${id}`;
    setLoadingPage(true);
    await axios
      .get(url, { headers })
      .then(res => {
        const resData = res?.data?.data;
        setPreload(resData);
        setLoadingPage(false);
      })
      .catch(error => {
        setLoadingPage(false);
        if (error?.response?.data?.errors?.length) {
          ModalError(error?.response?.data?.errors?.[0]?.message);
        }
      });
  };

  useEffect(() => {
    getDetailInvoice();
  }, []);

  const handlSubmitData = async payload => {
    try {
      setLoadingPage(true);
      await axios.put(`${hardBaseUrl}/publisher/transaction/${id}`, payload, {
        headers,
      });
      ModalSuccess("Invoice has been updated").then(() => history.goBack());
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <FormInvoice
      pageTitle="Edit Invoice"
      pageSubTitle="Edit your transaction here"
      loadingPage={loadingPage}
      onSubmitPayload={handlSubmitData}
      preload={preload}
      editMode
    />
  );
}

export default EditInvoice;
