import { Alert, Snackbar } from "@mui/material";

const AlertSnackbar = ({
  open,
  onClose,
  action,
  message,
  autoHideDuration,
  severity,
  ...rest
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 6000}
      onClose={onClose}
      action={action}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      {...rest}
    >
      <Alert
        onClose={onClose}
        severity={severity || "error"}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
