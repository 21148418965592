import moment from "moment";
export const TOKEN_HARD = localStorage.getItem("token");
export const hardSubDomain = localStorage.getItem("subDomain");
export const hardTypeWeb = localStorage.getItem("typeWeb");
export const hardToken = TOKEN_HARD;
export const feBaseUrl = process.env.REACT_APP_URL;
export const hardBaseUrl = process.env.REACT_APP_API_URL;
export const subDomain = process.env.REACT_APP_SUB_DOMAIN?.split(",") || [
  "nadaku",
  "caris",
  "aquarius",
  "mymusic",
  "musika",
];

export const getSubdomainFromCurrentURL = () => {
  try {
    // Ambil hostname dari URL saat ini
    const hostname = window.location.hostname;

    // Pisahkan hostname berdasarkan tanda titik (.)
    const parts = hostname.split(".");

    // Logika untuk subdomain di production (contoh: nadaku.caris.id)
    if (parts.length > 2) {
      return parts[0]; // Subdomain adalah bagian pertama
    }

    // Logika untuk subdomain di localhost (contoh: nadaku.localhost)
    if (hostname.includes("localhost")) {
      return parts.length === 2 ? parts[0] : null; // Subdomain adalah bagian pertama
    }

    // Jika tidak ada subdomain
    return null;
  } catch (error) {
    console.error("Error getting subdomain:", error);
    return null;
  }
};

const myFunc = () => {
  return "myFunc";
};

export const checkFromHelper = async () => {
  let afterCheckDomain = await myFunc();
  return afterCheckDomain;
};

export const daysAgo = date => {
  var date1 = new Date(date);
  var date2 = moment().toString();
  var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
  return `${diffDays} Hari yang lalu`;
};

export const imageToBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const imageToBase64WithNoFront = file => {
  return new Promise(resolve => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      let baseURLAfter = baseURL.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      );
      resolve(baseURLAfter);
    };
  });
};

export const imageBase64ToBlob = file => {
  return file;
};
